import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Employee, EmployeeFirstOfKin } from '../models';
import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmployeeFirstOfKinServices {
  constructor(private http: HttpClient) {}
  getByBusiness() {
    return this.http
      .get(
        environment.BASE_URL + '/employefirstofkins/' + BaseServices.BusinessId
      )
      .map((result: any) => (result ? result : null));
  }
  getById(id: number) {
    return this.http
      .get(environment.BASE_URL + '/employeefirstofkins/' + id)
      .map(result => result);
  }

  getEmployeeFirstOfKinByEmployeeId(id: number) {
    return this.http
      .get(environment.BASE_URL + '/firstofkinbyemployeeid/' + id)
      .map(result => result);
  }

  // add fok to list
  add(employee: EmployeeFirstOfKin) {
    return this.http
      .post(environment.BASE_URL + '/empfirstofkins', JSON.stringify(employee))
      .map(result => result);
  }

  // updating fok list
  update(employee: EmployeeFirstOfKin) {
    return this.http
      .put(
        environment.BASE_URL + '/employeefirstofkin/' + employee.Id,
        employee
      )
      .map(result => result);
  }
  deleteById(id: number) {
    return this.http
      .delete(environment.BASE_URL + '/employeefirstofkin/' + id)
      .map(result => result);
  }

  private extractData(res: any) {
    let body = res.json(); // return body || {};
    return <Employee[]>res.json();
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
