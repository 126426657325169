<div class="main-content">
  <div class="main-heading">
    <span translate>ADD_OBJECTS</span>
  </div>
  <div class="card">
    <div class="card-body">
      <form [formGroup]="foodSafetyForm" class="form">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="ZoneName" class="col-form-label">
                <span translate>TYPE_OF_OBJECT </span>
              </label>
              <div class="input-btn-group">
                <p-dropdown
                  [options]="typeOfObject"
                  formControlName="ObjectType"
                  [style]="{ width: '100%' }"
                  type="text"
                >
                </p-dropdown>
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  (click)="addObject = !addObject"
                >
                  <i class="pi pi-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div class="form-group">
              <label for="ZoneName" class="col-form-label">
                <span translate>OBJECT_NAME</span>
                <span class="required">*</span>
              </label>
              <div>
                <input
                  type="text"
                  formControlName="Title"
                  name="Title"
                  pInputText
                />
              </div>
              <control-messages
                [control]="foodSafetyForm.controls['ZoneName']"
              ></control-messages>
            </div>
            <div class="form-group">
              <label for="Description" class="col-form-label">
                <span translate>DESCRIPTION </span>
              </label>
              <div>
                <textarea
                  pInputTextarea
                  formControlName="Description"
                ></textarea>
              </div>
            </div>
            <div *ngIf="!disableZoneDropdown" class="form-group">
              <label for="ChooseProject" translate>COPY_EXISTING_OBJECT</label>
              <div>
                <p-dropdown
                  #chooseZone
                  [options]="fsZoneNames"
                  [filter]="true"
                  formControlName="ChooseZone"
                  [style]="{ width: '500px' }"
                  (onChange)="hideCopyFile($event.value)"
                ></p-dropdown>
              </div>
            </div>
            <div *ngIf="disableZoneDropdown" class="form-group">
              <label for="ChooseProject" translate>COPY_EXISTING_OBJECT</label>
              <div>
                <p-dropdown
                  #chooseZone
                  [disabled]="true"
                  [options]="fsZoneNames"
                  [filter]="true"
                  formControlName="ChooseZone"
                  [style]="{ width: '500px' }"
                  (onChange)="hideCopyFile($event.value)"
                ></p-dropdown>
              </div>
            </div>
            <div class="form-group">
              <p-checkbox
                *ngIf="!disableCopiedFiles"
                label="{{ 'COPY_FILES' | translate }}"
                formControlName="CopyFiles"
                binary="true"
                [hidden]="isHideFile || isTaskManager"
              ></p-checkbox>
              <p-checkbox
                *ngIf="disableCopiedFiles"
                [disabled]="true"
                pTooltip="{{ filesTemplateName }}"
                tooltipPosition="top"
                label="{{ 'COPY_FILES' | translate }}"
                formControlName="CopyFiles"
                binary="true"
                [hidden]="isTaskManager"
              ></p-checkbox>
              <p-checkbox
                *ngIf="!disableCopiedTask"
                label="{{ 'COPY_TASK' | translate }}"
                formControlName="CopyTask"
                binary="true"
                [hidden]="isHideFile || isDocuments"
              ></p-checkbox>
              <p-checkbox
                *ngIf="disableCopiedTask"
                [disabled]="true"
                pTooltip="{{ filesTemplateName }}"
                tooltipPosition="top"
                label="{{ 'COPY_TASK' | translate }}"
                formControlName="CopyTask"
                binary="true"
                [hidden]="isDocuments"
              ></p-checkbox>
              <p-checkbox
                *ngIf="!disableCopiedForm"
                value="CopySJAForm"
                label="{{ 'Copy_SJA_Form' | translate }}"
                formControlName="CopySJAForm"
                binary="true"
                [hidden]="isHideFile || isSJAForm"
              ></p-checkbox>
              <p-checkbox
                *ngIf="disableCopiedForm"
                [disabled]="true"
                value="CopySJAForm"
                label="{{ 'Copy_SJA_Form' | translate }}"
                formControlName="CopySJAForm"
                binary="true"
                [hidden]="isSJAForm"
              ></p-checkbox>
            </div>
            <div class="form-group">
              <p-checkbox
                label="{{ 'DO_NOT_DISPLAY_DEVIATION' | translate }}"
                formControlName="IsShowDeviationChecked"
                binary="true"
              ></p-checkbox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label translate>STATUS</label>
              <div>
                <div class="radio-inline" *ngFor="let option of options">
                  <label>
                    <input
                      type="radio"
                      formControlName="Status"
                      value="{{ option.Id }}"
                      (change)="statusChange($event, option.Id, option.Status)"
                      [checked]="option.Id == selectedOption"
                    />
                    {{ option.Status | translate }}
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="sub-panel-title">
                <span translate>ASSIGN_ACCESS</span>
              </div>
              <!-- Table starts -->
              <p-table
                #dt
                [value]="foodUsers"
                dataKey="Id"
                scrollable="true"
                scrollHeight="200px"
              >
                <!-- Header -->
                <ng-template pTemplate="header">
                  <tr>
                    <th>
                      <p-checkbox
                        [ngModel]="checkedAll"
                        (ngModelChange)="checkAll($event)"
                        [ngModelOptions]="{ standalone: true }"
                        binary="true"
                      ></p-checkbox>
                    </th>
                    <th>{{ "Name" | translate }}</th>
                  </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-object>
                  <tr>
                    <td>
                      <p-checkbox
                        [(ngModel)]="object.Id === userId || object.status"
                        [disabled]="object.Id === userId"
                        (ngModelChange)="checked(object)"
                        [ngModelOptions]="{ standalone: true }"
                        binary="true"
                      ></p-checkbox>
                    </td>
                    <td>
                      <span>{{ object.Name }}</span>
                    </td>
                  </tr>
                </ng-template>

                <!-- Empty message -->
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr>
                    <td colspan="2">
                      {{ "NO_RECORDS_FOUND" | translate }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <!-- Table Ends-->
            </div>
            <div class="form-group">
              <label>
                <span translate>EMAIL_NEWDEVIATION_LABEL</span>
              </label>
              <div *ngIf="bindSelectedUsers">
                <p-multiSelect
                  defaultLabel="{{ 'SELECT' | translate }}"
                  [options]="userSubscribers"
                  formControlName="selectedEmailSubscriber"
                  [style]="{ width: '100%' }"
                >
                </p-multiSelect>
              </div>
              <div *ngIf="bindAllUsers">
                <p-multiSelect
                  defaultLabel="{{ 'SELECT' | translate }}"
                  [dataKey]="'Id'"
                  [options]="selectedSubscribers"
                  formControlName="selectAllEmailSubscriber"
                  [style]="{ width: '100%' }"
                >
                </p-multiSelect>
              </div>
            </div>
          </div>
        </div>
        <div class="action-btns-wrapper">
          <a (click)="gotoBack()" class="btn btn-outline-secondary">
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
          </a>
          <button
            class="btn btn-primary"
            type="submit"
            (click)="saveFoodSafety()"
            [disabled]="!foodSafetyForm.valid"
          >
            <span translate>SAVE</span>
          </button>
          <button
            class="btn btn-primary"
            type="submit"
            (click)="saveAndCreateNew()"
            [disabled]="!foodSafetyForm.valid"
          >
            <span translate>SAVE_CREATE_NEW</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- Dialog box starts -->
<p-dialog
  header="{{ 'ADD/EDIT_OBJECT_TYPE' | translate }}"
  [(visible)]="addObject"
  (onHide)="onObjectTypePopupHide()"
  [style]="{ width: '500px', height: '500px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="objectTypePopup">
    <div class="form-group">
      <label class="col-form-label">
        <span translate>TYPE_OF_OBJECT</span>
      </label>
      <div>
        <input
          type="text"
          formControlName="ObjectType"
          class="label-input form-control"
          required
        />
      </div>
      <label
        class="col-form-label"
        *ngIf="isObjectTypeAllocated"
        [ngStyle]="{ color: 'red' }"
      >
        <span translate>CANNOT_DELETE</span>
      </label>
      <label
        class="col-form-label"
        *ngIf="isZoneNameExists"
        [ngStyle]="{ color: 'red' }"
      >
        <span translate>OBJECT_TYPE_ALREADY_EXIST</span>
      </label>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="listObjectType = !listObjectType"
        (click)="viewObject()"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="objectTypePopup.invalid"
        (click)="saveObjectType()"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="listObjectType">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #et
          [value]="typeOfObject"
          [rows]="5"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "TYPE_OF_OBJECT" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="et.filter($event.target?.value, 'label', 'contains')"
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-typeOfObject>
            <tr>
              <td>
                <span>{{ typeOfObject.label }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editObject(typeOfObject)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteObject(typeOfObject.value)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </p-footer>
</p-dialog>

<p-dialog
  header="{{ 'ADD_DEPARTMENT' | translate }}"
  (onHide)="onDepartmentPopupHide()"
  [(visible)]="addDepartment"
  [style]="{ width: '500px', height: '500px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="departmentPopup">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>NAME</span>
      </label>
      <div>
        <input
          type="text"
          formControlName="Name"
          class="label-input form-control"
          required
        />
      </div>
    </div>
    <label
      for="title "
      color
      class="col-form-label"
      *ngIf="isDepartmentNameAllocated"
      [ngStyle]="{ color: 'red' }"
    >
      <span translate>CANNOT_DELETE</span>
    </label>
    <label
      for="title "
      color
      class="col-form-label"
      *ngIf="isDepartmentNameExists"
      [ngStyle]="{ color: 'red' }"
    >
      <span translate>DEPARTMENT_EXIST</span>
    </label>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="listDepartment = !listDepartment"
        (click)="viewDepartment()"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="departmentPopup.invalid"
        (click)="saveDepartment()"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="listDepartment">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #et
          [value]="departmentList"
          [rows]="5"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "DEPARTMENT_NAME" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="et.filter($event.target?.value, 'Name', 'contains')"
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-department>
            <tr>
              <td>
                <span>{{ department.Name }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editDepartment(department)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteDepartment(department['Id'])"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends-->
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<toaster-component></toaster-component>
