import { UserService } from './../../../users/services/user.service';
import { FeatureKey, Rights } from './../../../../_models/feature';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { Location } from '@angular/common';
import * as _ from 'lodash';

import { EmployeeServices } from './../../../employees/services/employee.services';
import { ClientServices } from './../../services/client.services';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { BaseServices } from './../../../kuba.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Module, UserRole } from './../../../follow-ups/models/deviation';
import { ClientContact } from './../../models/clientContact';
import { Client, User } from './../../models/client';
import { EmployeeRights } from 'src/app/kuba/employees/models';
import { UserCredential } from 'src/app/kuba/users/models/user';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { FeaturesBaseServices } from 'src/app/kuba/features/services/features-base.services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ModuleList } from '../../models/module-list';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { HelperService } from 'src/app/_services/helper.service';
@Component({
  templateUrl: 'new-client.component.html',
})
export class CreateClientComponent implements OnInit {
  objBusineesDetails: any = {};
  objClient: any = {};
  ClientActive = false;
  ClientContactActive = true;
  //#region variable
  checkedContact: any = null;
  approvedDateContact: any;
  avatarNameContact: string;
  uploadStartContact: boolean;
  clientImageContact: string;

  checked: any = null;
  approvedDate: any;
  avatarName: string;
  uploadStart: boolean;
  public selectedFiles: any;
  businessId: number;
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  clientForm: FormGroup;
  isEditPage = true;
  cachedUserName: string;
  updatePasswordForm: FormGroup;
  userCredentials = new UserCredential();
  addclientContact = false;
  userData: any;
  clientContactsForm: FormGroup;
  clientContact: ClientContact[];
  passwordValidation = false;
  showPasswordResetDialog = false;
  isEmailExist = true;
  clientContacts = new ClientContact();
  userForm: FormGroup;
  role: SelectItem[];
  contact: SelectItem[];
  mobile: SelectItem[];
  Email: string;
  contacttype: any;
  clientId: any;
  addCategory = false;
  clientContactId: any;
  clientUserId: number;
  userCreation = true;
  moduleList: ModuleList[];
  rightsData: any;
  showPassword: any;
  hidden = false;
  clientContactUserId: any;
  isClientUser = true;
  userRightsData: any;
  isNewUser = true;
  isEdit = true;
  hideSaveUserButton = true;
  features: any = [];
  IsUserRole = true;
  isElectroExists: boolean;
  clientLogo: string;
  cachedClientNumber: number;
  canViewUser = true;
  electroRightsHead: string;
  private subscriptions: Subscription[] = [];
  isMobile: boolean;
  dialogDisplay: boolean = false;
  defaultPhoneCode;
  passwordsMatch: boolean = true;
  //#endregion

  //#region constructor

  /**
   * constructor
   * @param clientSvc {ClientServices}
   * @param _fb {FormBuilder}
   * @param location {Location}
   * @param route {ActivatedRoute}
   * @param confirmationService {ConfirmationService}
   */
  constructor(
    private clientSvc: ClientServices,
    private _fb: FormBuilder,
    private location: Location,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private businessServices: BusinessServices,
    private router: Router,
    private translate: TranslateService,
    private userService: UserService,
    private employeeService: EmployeeServices,
    public featuresBaseServices: FeaturesBaseServices,
    private http: HttpClient
  ) {
    if (BaseServices.roleId === '1' || BaseServices.roleId === '2') {
      this.businessId = this.route.snapshot.parent!.params['bid'];
    } else {
      this.businessId = BaseServices.BusinessId;
    }
    this.updatePasswordForm = this._fb.group({
      passwordValidation: this._fb.group(
        {
          Password: ['', [Validators.required]],
          ConfirmPassword: ['', [Validators.required]],
        },
        { validator: ValidationService.matchPassword }
      ),
    });
    this.clientForm = this._fb.group({
      ClientType: ['', Validators.required],
      Number: ['', Validators.required, this.isclientNumUnique.bind(this)],
      Name: ['', Validators.required],
      OrganizationNumber: [''],
      Address: [''],
      Location: [''],
      Phonecode: [''],
      Mobile: [''],
      Telephone: [''],
      Fax: [''],
      Email: ['', [Validators.required, ValidationService.emailValidator]],
      Owner: [''],
      CEO: [''],
      NoOfEmployees: [''],
      HomepageAddress: [''],
      Comment: [''],
      Zip: [''],
      IsApprovalNotice: [''],
    });
    this.clientContactsForm = this._fb.group({
      ContactName: ['', Validators.required],
      ContactMobile: [''],
      Phonecode: [''],
      ContactEmailId: [
        '',
        [Validators.required, ValidationService.emailValidator],
      ],
      IsSms: [''],
      IsEmail: [''],
      IsApprovalNotice: [''],
    });
    this.role = [];
    this.role.push({ label: 'Guest', value: 4 });
    let myPage = this.route.snapshot.queryParams['myPage'];
    if (BaseServices.roleId === '1' || BaseServices.roleId === '2') {
      this.businessId = this.route.snapshot.parent!.params['bid'];
    } else {
      this.businessId = BaseServices.BusinessId;
    }
    if (myPage) {
      this.isEdit = false;
    }
  }

  //#endregion

  //#region page-event

  /**
   * page init
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    if (BaseServices.roleId === '4') {
      this.ClientActive = true;
      if (BaseServices.FeatureId === FeatureKey.CLIENT_CONTACT) {
        this.ClientContactActive = false;
      }
    }

    let BusinessDetails = JSON.parse(localStorage.getItem('BusinessDetails')!);
    this.objBusineesDetails.Name = BusinessDetails.CompanyName;
    this.objBusineesDetails.Address = BusinessDetails.Address;
    this.objBusineesDetails.Zip = BusinessDetails.Zipcode;
    this.objBusineesDetails.Phone = BusinessDetails.Telephone;
    this.objBusineesDetails.Email = BusinessDetails.Email;
    this.userCreation = false;
    this.moduleList = this.route.snapshot.data['module'];
    let featureId = BaseServices.FeatureId;
    if (
      featureId === FeatureKey.SUB_CONTRACTOR ||
      featureId === FeatureKey.CLIENT ||
      BaseServices.UserRole === 'User' ||
      featureId === FeatureKey.CLIENT_CONTACT ||
      featureId === FeatureKey.CONTRACTOR_CONTACT ||
      BaseServices.UserRole === 'Guest'
    ) {
      this.IsUserRole = false;
    }
    let businessFeatures = BaseServices.userFeatures;
    if (window.name === 'Remote') {
      this.subscriptions.push(
        this.businessServices
          .getById(BaseServices.BusinessId)
          .subscribe((result) => {
            if (result) {
              businessFeatures = <any>JSON.parse(result.Features);
              let mainFeature = businessFeatures.filter(
                (x: any) => x.id === FeatureKey.ELECTRO
              );
              if (mainFeature.length) {
                this.isElectroExists = mainFeature[0].checked ? true : false;
              }
            }
          })
      );
    } else {
      let mainFeature = businessFeatures.filter(
        (x: any) => x.id === FeatureKey.ELECTRO
      );
      if (mainFeature.length) {
        this.isElectroExists = mainFeature[0].checked ? true : false;
      }
    }
    if (BaseServices.UserRole === 'User') {
      this.IsUserRole = false;
    }
    let cid = this.route.snapshot.params['cid'];
    this.clientId = cid || this.clientId ? +cid : 0;
    this.clientUserExitence();

    this.mobile = [];
    let mobileCode = this.route.snapshot.data['countryCode'];
    if (mobileCode) {
      mobileCode.forEach((phoneType: any) => {
        this.mobile.push({
          label: '+' + phoneType.Phonecode,
          value: phoneType.Id,
        });
      });
      // Find the value in this.mobile that matches +47
      this.defaultPhoneCode = this.mobile.find(
        (phone) => phone.label === '+47'
      );
      if (this.clientId < 1) {
        // Set the default country Code in the form controls for client and client contact when creating new
        if (this.defaultPhoneCode) {
          this.clientForm.controls['Phonecode'].setValue(
            this.defaultPhoneCode.value
          );
        }
      }
      this.clientContactsForm.controls['Phonecode'].setValue(
        this.defaultPhoneCode.value
      );
    }

    if (this.clientId > 0) {
      this.addclientContact = true;
      this.clientContact = <ClientContact[]>(
        this.route.snapshot.data['contactlist']
      );
      this.clientContact = this.clientContact.filter(
        (x: any) => x.ClientId === this.clientId
      );
      let result = this.route.snapshot.data['edit'];

      if (result) {
        this.cachedClientNumber = result.Number;
        this.isEditPage = false;
        this.Email = result.Email;
        this.objClient.Name = result.Name;
        this.objClient.Address = result.Address;
        this.objClient.Zip = result.Zip;
        this.objClient.Telephone = result.Telephone;
        this.objClient.Email = result.Email;
        this.objClient.ClientimageURL = result.Avatar;
        let client = {
          Name: result.Name,
          ClientType: result.ContactTypeId,
          Mobile: result.Mobile,
          Telephone: result.Telephone,
          Email: result.Email,
          Number: result.Number,
          Fax: result.Fax,
          Address: result.Address,
          Zip: result.Zip,
          Location: result.Location,
          Comment: result.Comment,
          Phonecode: result.CountryCode ? result.CountryCode : '',

          OrganizationNumber: result.OrganizationNumber
            ? result.OrganizationNumber
            : '',
          Owner: result.Owner ? result.Owner : '',
          CEO: result.CEO ? result.CEO : '',
          NoOfEmployees: result.NoOfEmployees ? result.NoOfEmployees : '',
          HomepageAddress: result.HomepageAddress ? result.HomepageAddress : '',
          IsApprovalNotice: result.IsApprovalNotice,
        };

        // Ensure this.mobile is defined before using it
        if (this.mobile && this.mobile.length > 0) {
          // Convert CountryCode to string if it's not already
          const countryCodeStr = result.CountryCode.toString();

          // Find the corresponding value for the Phonecode
          const phonecodeValue = this.mobile.find(
            (phone) => phone.value.toString() === countryCodeStr
          )?.value;

          client.Phonecode = phonecodeValue || ''; // Set the value instead of the label
        }

        this.clientLogo = result.ClientLogo ? result.ClientLogo : null;

        (<FormGroup>this.clientForm).setValue(client, { onlySelf: true });

        if (result.IsApprovalNotice) {
          this.checked = true;
          this.approvedDate = result.ApprovalDateTime;
        }
        this.clientLogo = this.avatarName = result.Avatar
          ? result.Avatar
          : null;
      }
    }

    this.subscriptions.push(
      this.translate.stream('CLIENT_TYPES').subscribe((val) => {
        this.contact = [];
        this.contact.push(
          { label: val.SELECT, value: null },
          { label: val.PERSON, value: 1 },
          { label: val.COMPANY, value: 2 }
        );
      })
    );

    this.initForm();

    if (
      +BaseServices.roleId === UserRole.USER ||
      +BaseServices.UserId === UserRole.GUEST
    ) {
      this.canViewUser = false;
    }
  }

  /**
   * upload for employee image
   * @param selectedFiles
   */
  filesSelect(selectedFiles: any): void {
    this.uploadStart = true;
    this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(
      (file) => file
    );
    let formData = new FormData();
    formData.append('file', this.selectedFiles[0]);

    this.subscriptions.push(
      this.http
        .post(
          environment.BASE_URL +
            '/client/file/upload/client-logo/' +
            BaseServices.ApplicationId +
            '/' +
            this.businessId,
          formData
        )
        .map((response: any) => {
          this.uploadStart = false;
          let res = response;
          this.clientLogo = res.Result.path;
        })
        .subscribe(
          (result) => {},
          (error) => console.log('Upload logo Sign Error : ', error),
          () => console.log('Upload logo  Sign Complete')
        )
    );
  }

  /**
   * Initialize the form
   * @param data {any}
   */
  initForm(data?: any) {
    let Id: number;
    let Name = '';
    let Email = '';
    let Username = '';
    let Password = '';
    let RoleId = '';
    let Status = '';
    let Phonecode = '';
    let Mobile = '';
    if (data) {
      this.cachedUserName = data.Username;
      this.userData = data;
      Id = data.Id ? data.Id : '0';
      Name = data.Name ? data.Name : '';
      Email = data.Email ? data.Email : '';
      Username = data.Username ? data.Username : '';
      Password = data.Password ? data.Password : '';
      RoleId = data.RoleId ? data.RoleId : '4';
      Status = data.Status ? data.Status : '1';
      Phonecode = data.Phonecode ? data.Phonecode : '';
      Mobile = data.Mobile ? data.Mobile : '';
      this.rightsData = data.Rights
        ? JSON.parse(data.Rights)
        : this.userRightsData;
    }
    let allCategories: FormArray | any = new FormArray([]);

    this.userForm = new FormGroup({
      Id: new FormControl(Id!),
      Name: new FormControl(Name),
      Email: new FormControl(Email, [Validators.required, Validators.email]),
      Username: new FormControl(
        Username,
        [Validators.required, ValidationService.noWhitespaceValidator],
        this.isUserNameUnique.bind(this)
      ),
      Password: new FormControl('', [Validators.required, this.passwordMatchValidator.bind(this)]),
      RepeatPassword: new FormControl('', [Validators.required]),
      RoleId: new FormControl(RoleId),
      Status: new FormControl(Status),
      Phonecode: new FormControl(Phonecode),
      Mobile: new FormControl(Mobile),
      categories: allCategories,
    });
    
    this.bindRightsCheckbox(data);
  }
  bindRightsCheckbox(data?: any) {
    this.userRightsData = [
      {
        id: '1',
        name: 'ACTIVE_ACCOUNT',
        isChecked: true,
        rightsType: 1,
      },
      {
        id: '20',
        name: 'DEVIATION_RIGHTS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '24',
        name: 'CHECKLIST_RIGHTS',
        isChecked: false,
        rightsType: 1,
      },
    ];
    if (this.isElectroExists) {
      this.electroRightsHead =
        BaseServices.ApplicationId === 4 ? 'IK_CLIENT' : 'ELECTRO';
      let electroGuestRights = [
        {
          id: '41',
          name: 'HIDE_DEVIATIONS',
          isChecked: false,
          rightsType: 7,
        },
        {
          id: '42',
          name: 'DEVIATIONS_READONLY',
          isChecked: false,
          rightsType: 7,
        },
        {
          id: '43',
          name: 'EDIT_DEVIATIONS',
          isChecked: false,
          rightsType: 7,
        },
        {
          id: '44',
          name: 'ADD_DEVIATIONS',
          isChecked: false,
          rightsType: 7,
        },
      ];
      this.userRightsData.push(...electroGuestRights);
    }
    const userControl = <FormArray>this.userForm.controls['categories'];
    for (let i = 0; i < this.userRightsData.length; i++) {
      let fg = new FormGroup({});
      fg.addControl(
        `${this.userRightsData[i].id}`,
        new FormControl(
          data
            ? this.arrayCompare(data.Rights, this.userRightsData[i].id)
            : this.userRightsData[i].isChecked
        )
      );
      userControl.push(fg);
    }
  }
  //#endregion

  //#region control-event
  /**
   * mobilenumber keypress event restrict number greater than 20 and restrict text
   * @param e {any}
   * @param limitNumber {any}
   */
  onMobileNumber(e: any, limitNumber: any) {
    const limit = limitNumber;
    let charCode = e.which ? e.which : e.keyCode;
    if (
      e.target.value.length === limit ||
      (charCode > 31 && (charCode < 48 || charCode > 57))
    ) {
      e.preventDefault();
    }
  }
  onRightsCheckboxChange(e: any, rightsId: any) {
    let userRightsData = <FormArray>this.userForm.controls['categories'];
    if (
      e.target.checked &&
      (+rightsId === Rights.HIDE_DEVIATIONS ||
        +rightsId === Rights.DEVIATIONS_READONLY ||
        +rightsId === Rights.EDIT_DEVIATIONS ||
        +rightsId === Rights.ADD_DEVIATIONS)
    ) {
      let electroDevRights = [
        Rights.HIDE_DEVIATIONS,
        Rights.DEVIATIONS_READONLY,
        Rights.EDIT_DEVIATIONS,
        Rights.ADD_DEVIATIONS,
      ];
      userRightsData['controls'].forEach((rightControl) => {
        if (
          electroDevRights.indexOf(+Object.keys(rightControl.value)[0]) > -1
        ) {
          if (+Object.keys(rightControl.value)[0] !== +rightsId) {
            rightControl.patchValue({
              [Object.keys(rightControl.value)[0]]: false,
            });
            if (this.rightsData) {
              _.each(this.rightsData, (x: EmployeeRights) => {
                if (+x.id === +Object.keys(rightControl.value)[0]) {
                  x.isChecked = false;
                }
              });
            }
          }
        }
      });
    }
    if (this.rightsData) {
      _.each(this.rightsData, (x: EmployeeRights) => {
        if (x.id === rightsId) {
          x.isChecked = e.target.checked;
        }
      });
    }
  }
  //#endregion

  //#region methods
  /**
   * To array compare
   * @param list {any}
   * @param key {number}
   */
  arrayCompare(list: any, key: number) {
    let isMatched = false;
    let dataList = JSON.parse(list || null);
    if (dataList && dataList.length > 0) {
      for (let index = 0; index < dataList.length; index++) {
        let element = dataList[index];
        if (element.id === key) {
          return element.isChecked;
        }
      }
    }
    return isMatched;
  }

  getFeatureDetail(projectId: number) {
    this.subscriptions.push(
      this.businessServices
        .getById(BaseServices.BusinessId)
        .subscribe((result) => {
          let feature = result.Features;
          let projectAdditionalFeature = _.find(JSON.parse(feature), {
            id: Module.PROJECT,
            access: true,
          });
          this.router.navigate(
            [
              `./../../../project/details/${projectId}/document/list`,
              { Id: projectId },
            ],
            { relativeTo: this.route }
          );
        })
    );
  }
  /**
   * client save
   */
  saveClient() {
    this.addclientContact = true;
    if (this.clientForm.valid) {
      let client = new Client();
      client.Id = this.clientId ? this.clientId : 0;
      client.BusinessId = BaseServices.BusinessId;
      client.ContactTypeId = this.clientForm.value.ClientType;
      client.Name = this.clientForm.value.Name;
      client.Number = this.clientForm.value.Number;
      client.Fax = this.clientForm.value.Fax;
      client.Address = this.clientForm.value.Address;
      client.Zip = this.clientForm.value.Zip;
      client.Location = this.clientForm.value.Location;
      client.Mobile = this.clientForm.value.Mobile;
      client.Telephone = this.clientForm.value.Telephone;
      client.CountryCode = this.clientForm.value.Phonecode;
      client.Telephone = this.clientForm.value.Telephone;
      client.Email = this.clientForm.value.Email;
      client.Comment = this.clientForm.value.Comment;
      client.IsElectro = this.isElectroExists;
      client.OrganizationNumber = this.clientForm.value.OrganizationNumber;
      client.ClientNumber = this.clientForm.value.Number;
      client.Owner = this.clientForm.value.Owner;
      client.CEO = this.clientForm.value.CEO;
      client.NoOfEmployees = this.clientForm.value.NoOfEmployees;
      client.HomepageAddress = this.clientForm.value.HomepageAddress;
      client.ClientLogo = this.clientLogo;
      client.Avatar = this.clientLogo;
      client.IsApprovalNotice = this.clientForm.value.IsApprovalNotice;
      client.ApprovalDateTime = this.approvedDate;
      client.Status = '1';
      if (this.clientId > 0) {
        client.ModifiedBy = BaseServices.UserId;
        this.subscriptions.push(
          this.clientSvc.updateClient(client).subscribe((isClientUpdated) => {
            if (isClientUpdated) {
              this.toasterComponent.callToast();
            }
          })
        );
      } else {
        client.CreatedBy = BaseServices.UserId;
        this.subscriptions.push(
          this.clientSvc
            .createClient(client)
            .subscribe((isClientAdded: any) => {
              if (isClientAdded) {
                this.clientId = isClientAdded ? isClientAdded.Id : 0;
                this.toasterComponent.callToast();
              }
            })
        );
      }
    }
  }
  /**
   * form reset
   */
  clear() {
    this.clientContactId = '0';
    this.clientContactsForm.reset();
    this.clientImageContact = null!;
    this.clientContactsForm.controls['Phonecode'].setValue(
      this.defaultPhoneCode.value
    );
  }
  /**
   * edit client contact
   * @param data {any}
   */
  editClientContact(data: any) {
    this.clientContacts = data;
    this.subscriptions.push(
      this.clientSvc.getClientContactUser(data.Id).subscribe((userContact) => {
        if (userContact == null) {
          this.clientContactUserId = null;
        } else {
          this.clientContactUserId = userContact.Id;
        }
        this.clientContactId = data.Id;
        this.clientContactUserExitence();
        if (data.IsUser == true) {
          this.userCreation = true;
        } else {
          this.userCreation = false;
        }
        if (data) {
          let contact = {
            ContactName: data.Name,
            ContactMobile: data.Mobile,
            ContactEmailId: data.Email,
            IsSms: data.IsSms,
            IsEmail: data.IsEmail,
            Phonecode: data.CountryCode || '',
            IsApprovalNotice: data.IsApprovalNotice,
          };

          // Ensure this.mobile is defined before using it
          if (this.mobile && this.mobile.length > 0) {
            // Convert CountryCode to string if it's not already
            const countryCodeStr = data.CountryCode.toString();

            // Find the corresponding value for the Phonecode
            const phonecodeValue = this.mobile.find(
              (phone) => phone.value.toString() === countryCodeStr
            )?.value;

            contact.Phonecode = phonecodeValue || ''; // Set the value instead of the label
          }

          this.clientContactsForm = this._fb.group(contact);

          if (data.IsApprovalNotice) {
            this.checkedContact = true;
            this.approvedDateContact = data.ApprovalDateTime;
          }
          this.clientImageContact = this.avatarNameContact = data.Avatar
            ? data.Avatar
            : null;
        }
      })
    );
  }

  /**
   * save client contact
   */
  saveClientContact() {
    if (this.clientContactsForm.valid) {
      this.clientContacts.Id = this.clientContactId ? this.clientContactId : 0;
      this.clientContacts.ClientId = this.clientId;
      this.clientContacts.Name = this.clientContactsForm.value.ContactName;
      this.clientContacts.Mobile = this.clientContactsForm.value.ContactMobile;
      this.clientContacts.Email = this.clientContactsForm.value.ContactEmailId;
      this.clientContacts.IsSms = this.clientContactsForm.value.IsSms;
      this.clientContacts.IsEmail = this.clientContactsForm.value.IsEmail;
      this.clientContacts.CountryCode = this.clientContactsForm.value.Phonecode;
      this.clientContacts.Status = '1';
      this.clientContacts.Avatar = this.clientImageContact;
      this.clientContacts.IsApprovalNotice =
        this.clientContactsForm.value.IsApprovalNotice;
      this.clientContacts.ApprovalDateTime = this.approvedDateContact;
      if (this.clientContacts.Id > 0 && this.clientContacts.Id != null) {
        this.clientContacts.ModifiedBy = BaseServices.UserId;
        this.subscriptions.push(
          this.clientSvc
            .updateClientContact(this.clientContacts)
            .subscribe((isContactUpdated) => {
              if (isContactUpdated) {
                this.toasterComponent.callToast();
                this.getClientContact();
              }
            })
        );
      } else {
        this.clientContacts.CreatedBy = BaseServices.UserId;
        this.subscriptions.push(
          this.clientSvc
            .addClientContact(this.clientContacts)
            .subscribe((isContactAdded: any) => {
              if (isContactAdded) {
                this.clientContacts.Id = isContactAdded.Id;
                this.toasterComponent.callToast();
                this.getClientContact();
              }
            })
        );
      }
    }
    this.clear();
    this.clearUser();
  }
  /**
   * save user client
   */
  saveUserClient() {
    this.isClientUser = false;
    if (this.clientContactUserId > 0 && this.clientContactUserId != null) {
      this.subscriptions.push(
        this.clientSvc
          .getUserById(this.clientContactUserId)
          .subscribe((user) => {
            if (user) {
              this.initForm(user);
              this.isNewUser = false;
            }
          })
      );
    } else {
      let userData = {
        Id: this.clientContactUserId ? this.clientContactUserId : 0,
        Name: this.clientContactsForm.value.ContactName,
        Email: this.clientContactsForm.value.ContactEmailId,
        Username: this.clientContactsForm.value.ContactEmailId,
        Phonecode: this.clientContactsForm.value.Phonecode,
        Mobile: this.clientContactsForm.value.ContactMobile,
      };
      this.initForm(userData);
      this.isNewUser = true;
    }
    if (
      this.clientContactsForm.value.ContactMobile == '' ||
      this.clientContactsForm.value.ContactMobile == null
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.addCategory = true;
    this.getClientContact();
  }
  /**
   * mobile number validation
   * @param e {any}
   * @param limitNumber {any}
   */
  onNumberChange(e: any, limitNumber: any) {
    HelperService.numberFieldValidation(e, limitNumber);
  }
  MobileEvent(event) {
    var mobileNum = document.getElementById('mobilenum') as HTMLButtonElement;
    if (mobileNum.value.length >= 7) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }
  clearUser() {
    this.clientContactUserId = '0';
    this.initForm();
    this.userData = '';
  }

  /**
   * delete the client contact
   * @param clientContact {any}
   */
  deleteConfirm(clientContact: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.clientSvc
            .deleteClientContact(clientContact)
            .subscribe((isContactDeleted) => {
              if (isContactDeleted) {
                this.clear();
                this.toasterComponent.callToastDlt();
                this.getClientContact();
              }
            })
        );
      },
    });
  }
  /**
   * Edit the client details
   */
  saveProceed() {
    this.addclientContact = true;
    this.isClientUser = true;
    this.subscriptions.push(
      this.clientSvc.getClientUser(this.clientId).subscribe((result) => {
        this.clientUserId = result ? result.Id : 0;
        if (this.clientUserId > 0) {
          this.subscriptions.push(
            this.clientSvc.getUserById(this.clientUserId).subscribe((data) => {
              this.initForm(data);
              this.addCategory = true;
              this.isNewUser = false;
            })
          );
        } else {
          this.saveClient();
          let userData = {
            Id: this.clientUserId ? this.clientUserId : 0,
            Name: this.clientForm.value.Name,
            Email: this.clientForm.value.Email,
            Username: this.clientForm.value.Email,
            Phonecode: this.clientForm.value.Phonecode,
            Mobile: this.clientForm.value.Mobile,
          };
          this.initForm(userData);
          this.addCategory = true;
        }
      })
    );
  }
  /**
   * generate random password
   */
  generateRandomPassword() {
    let randomstring = Math.random().toString(36).slice(-8);
    this.userForm.patchValue({
      Password: randomstring,
      RepeatPassword: randomstring
    });  
    // Ensure validation updates
    this.userForm.get('Password')!.updateValueAndValidity();
    this.userForm.get('RepeatPassword')!.updateValueAndValidity();  
    this.showPassword = randomstring;
    this.hidden = true;
  }
  
  passwordMatchValidator(control: AbstractControl) {
    if (!this.userForm) return null;  
    const password = this.userForm.get('Password')!.value;
    const repeatPassword = control.value;  
    return password === repeatPassword ? null : { passwordMismatch: true };
  }
  onPasswordChange(event: any, field: string) {
    const value = event.target.value;
    this.userForm.get(field)!.setValue(value, { emitEvent: true });
    this.userForm.get(field)!.updateValueAndValidity();
    this.checkPasswords();
  }
  checkPasswords() {
    const password = this.userForm.get('Password')!.value;
    const repeatPassword = this.userForm.get('RepeatPassword')!.value;
    this.passwordsMatch = password === repeatPassword;
  }
  // type of user
  /**
   * save user
   */
  saveUser() {
    let userdetails = new User();
    userdetails.Id = this.userForm.value.Id;
    userdetails.FeatureId = this.isClientUser
      ? Module.CLIENT
      : Module.CLIENT_CONTACT;
    userdetails.FeatureKey = this.isClientUser
      ? this.clientId
      : this.clientContactId;
    userdetails.Email = this.userForm.value.Email;
    userdetails.Name = this.userForm.value.Name;
    userdetails.Password = this.userForm.value.Password;
    userdetails.BusinessId = BaseServices.BusinessId;
    userdetails.Status = '1';
    userdetails.Username = this.userForm.value.Username;
    userdetails.RoleId = this.userForm.value.RoleId;
    userdetails.Mobile = this.userForm.value.Mobile;
    userdetails.ApplicationId = BaseServices.ApplicationId;
    userdetails.CreatedBy = BaseServices.UserId;
    if (window.name === 'Remote' && this.userForm.value.Id === '0') {
      userdetails.LanguageId = 2; // default language Norsk
    } else {
      userdetails.LanguageId = BaseServices.userLanguageId;
    }
    if (this.rightsData && this.rightsData.length > 0) {
      userdetails.Rights = JSON.stringify(this.rightsData);
    } else {
      userdetails.Rights = null!;
    }

    if (this.userForm.value.Id > 0) {
      this.subscriptions.push(
        this.clientSvc.updateUser(userdetails).subscribe((isUpdated) => {
          if (isUpdated) {
            this.toasterComponent.callToast();
          }
        })
      );
    }
    else {
      if (this.clientContactsForm.valid) {
        this.clientContacts.Id = this.clientContactId
          ? this.clientContactId
          : 0;
        this.clientContacts.ClientId = this.clientId;
        this.clientContacts.Name = this.clientContactsForm.value.ContactName;
        this.clientContacts.Mobile =
          this.clientContactsForm.value.ContactMobile;
        this.clientContacts.Email =
          this.clientContactsForm.value.ContactEmailId;
        this.clientContacts.IsSms = this.clientContactsForm.value.IsSms;
        this.clientContacts.IsEmail = this.clientContactsForm.value.IsEmail;
        this.clientContacts.CountryCode =
          this.clientContactsForm.value.Phonecode;
        this.clientContacts.Status = '1';
        this.clientContacts.Avatar = this.clientImageContact;
        this.clientContacts.IsApprovalNotice =
          this.clientContactsForm.value.IsApprovalNotice;
        this.clientContacts.ApprovalDateTime = this.approvedDateContact;
        if (
          this.clientContacts.Id > 0 &&
          this.clientContacts.Id != null &&
          this.clientContacts.IsUser != null
        ) {
          this.clientContacts.ModifiedBy = BaseServices.UserId;
          this.subscriptions.push(
            this.clientSvc
              .updateClientContact(this.clientContacts)
              .subscribe((isContactUpdated: any) => {
                this.clientId = isContactUpdated.ClientId;
                if (isContactUpdated) {
                  this.toasterComponent.callToast();
                  this.getClientContact();
                }
              })
          );
        } else if (this.userForm.value.Id == 0 && this.clientContacts.Id > 0) {
          this.subscriptions.push(
            this.clientSvc.addUser(userdetails).subscribe((res) => {
              if (!this.isClientUser) {
                this.clientContacts.IsUser = true;
                this.subscriptions.push(
                  this.clientSvc
                    .updateClientContact(this.clientContacts)
                    .subscribe((res: any) => {
                      this.clientId = res.ClientId;
                    })
                );
                this.toasterComponent.callToast();
                this.getClientContact();
              }
            })
          );
        } else {
          this.clientContacts.CreatedBy = BaseServices.UserId;
          this.clientContacts.IsUser = true;
          this.subscriptions.push(
            this.clientSvc
              .addClientContact(this.clientContacts)
              .subscribe((isContactAdded: any) => {
                if (isContactAdded) {
                  this.clientContacts.Id = isContactAdded.Id;
                  this.toasterComponent.callToast();
                  this.getClientContact();
                }
                userdetails.FeatureKey = isContactAdded.Id;
                this.subscriptions.push(
                  this.clientSvc.addUser(userdetails).subscribe((res) => {})
                );
              })
          );
        }
      }
    }
    this.addCategory = false;
    if (this.userForm.value.Id > 0) {
      this.toasterComponent.callToast();
    }
    this.clear();
    this.ngOnInit();
    this.getClientContact();
  }
  /**
   * client contact info
   */
  getClientContact() {
    this.subscriptions.push(
      this.clientSvc.getClientContact().subscribe((contactInfo: any) => {
        if (contactInfo) {
          this.clientContact = contactInfo.filter(
            (x: any) => x.ClientId === this.clientId
          );
        }
      })
    );
  }
  /**
   * back to list
   */
  gotoList() {
    this.location.back();
  }
  userClear() {
    this.showPasswordResetDialog = false;
    this.updatePasswordForm.reset();
  }
  showResetPassword() {
    this.showPasswordResetDialog = true;
  }
  /**
   * validation for organization number
   * @param control
   */
  isEmailUnique(control: FormControl) {
    const q = new Promise((resolve) => {
      setTimeout(() => {
        if (control.value === '' || control.value === this.Email) {
          resolve(null);
        } else {
          this.subscriptions.push(
            this.clientSvc.getAllClientsByBusiness().subscribe(
              (x: any) => {
                let isEmailExist = x.filter(
                  (x: any) => x.Email === control.value
                );
                if (isEmailExist.length > 0) {
                  resolve({ isEmailUnique: true });
                  this.isEmailExist = false;
                } else {
                  resolve(null);
                  this.isEmailExist = true;
                }
              },
              () => {
                this.isEmailExist = true;
                resolve({ isEmailUnique: true });
              }
            )
          );
        }
      }, 1000);
    });
    return q;
  }
  resetPassWord() {
    this.userCredentials.Id = this.userForm.value.Id;
    this.userCredentials.Password =
      this.updatePasswordForm.value.passwordValidation.Password;
    this.userCredentials.Password =
      this.updatePasswordForm.value.passwordValidation.ConfirmPassword;
    this.subscriptions.push(
      this.businessServices
        .updatePassword(this.userCredentials)
        .subscribe((result) => {
          if (result === false) {
            this.passwordValidation = true;
          } else {
            this.toasterComponent.callToast();
          }
        })
    );
  }
  refreshUsername() {
    if (this.cachedUserName) {
      this.userForm.get('Username')!.patchValue(this.cachedUserName);
    } else {
      this.userForm.get('Username')!.patchValue(this.clientForm.value.Email);
    }
  }
  clearUserName() {
    this.userForm.get('Username')!.patchValue('');
  }
  /**
   * user name validation
   */
  isUserNameUnique(control: FormControl) {
    const q = new Promise((resolve) => {
      setTimeout(() => {
        if (control.value === this.cachedUserName) {
          resolve(null);
        } else {
          this.subscriptions.push(
            this.employeeService.getUserName(control.value).subscribe(
              (x) => {
                if (!x) {
                  resolve(null);
                } else {
                  resolve({ invalidUserNameWithId: true });
                }
              },
              () => {
                resolve({ invalidUserNameWithId: true });
              }
            )
          );
        }
      }, 1000);
    });
    return q;
  }

  clientUserExitence() {
    this.subscriptions.push(
      this.userService
        .getUserExits(Module.CLIENT, this.clientId)
        .subscribe((res) => {
          if (res) {
            this.hideSaveUserButton = false;
          }
        })
    );
  }
  clientContactUserExitence() {
    this.subscriptions.push(
      this.userService
        .getUserExits(Module.CLIENT_CONTACT, this.clientContactId)
        .subscribe((res) => {})
    );
  }
  showDialog() {
    this.dialogDisplay = true;
  }
  saveAndSendSMS() {
    let userdetails = new User();
    userdetails.Id = this.userForm.value.Id;
    userdetails.FeatureId = this.isClientUser
      ? Module.CLIENT
      : Module.CLIENT_CONTACT;
    userdetails.FeatureKey = this.isClientUser
      ? this.clientId
      : this.clientContactId;
    userdetails.Email = this.userForm.value.Email;
    userdetails.Name = this.userForm.value.Name;
    userdetails.Password = this.userForm.value.Password;
    userdetails.BusinessId = BaseServices.BusinessId;
    userdetails.Status = '1';
    userdetails.Username = this.userForm.value.Username;
    userdetails.RoleId = this.userForm.value.RoleId;
    userdetails.Mobile = this.userForm.value.Mobile;
    userdetails.ApplicationId = BaseServices.ApplicationId;
    userdetails.CountryCode = this.userForm.value.Phonecode;

    if (window.name === 'Remote' && this.userForm.value.Id === '0') {
      userdetails.LanguageId = 2; // default language Norsk
    } else {
      userdetails.LanguageId = BaseServices.userLanguageId;
    }
    if (this.rightsData && this.rightsData.length > 0) {
      userdetails.Rights = JSON.stringify(this.rightsData);
    } else {
      userdetails.Rights = null!;
    }
    if (this.userForm.value.Id > 0) {
      this.subscriptions.push(
        this.clientSvc.updateUser(userdetails).subscribe((isUpdated) => {
          if (isUpdated) {
            this.toasterComponent.callToast();
          }
        })
      );
    } else {
      if (this.clientContactsForm.valid) {
        this.clientContacts.Id = this.clientContactId
          ? this.clientContactId
          : 0;
        this.clientContacts.ClientId = this.clientId;
        this.clientContacts.Name = this.clientContactsForm.value.ContactName;
        this.clientContacts.Mobile =
          this.clientContactsForm.value.ContactMobile;
        this.clientContacts.Email =
          this.clientContactsForm.value.ContactEmailId;
        this.clientContacts.IsSms = this.clientContactsForm.value.IsSms;
        this.clientContacts.IsEmail = this.clientContactsForm.value.IsEmail;
        this.clientContacts.CountryCode =
          this.clientContactsForm.value.Phonecode;
        this.clientContacts.Status = '1';
        this.clientContacts.Avatar = this.clientImageContact;
        this.clientContacts.IsApprovalNotice =
          this.clientContactsForm.value.IsApprovalNotice;
        this.clientContacts.ApprovalDateTime = this.approvedDateContact;
        if (
          this.clientContacts.Id > 0 &&
          this.clientContacts.Id != null &&
          this.clientContacts.IsUser != null
        ) {
          this.clientContacts.ModifiedBy = BaseServices.UserId;
          this.subscriptions.push(
            this.clientSvc
              .updateClientContact(this.clientContacts)
              .subscribe((isContactUpdated) => {
                if (isContactUpdated) {
                  this.toasterComponent.callToast();
                  this.getClientContact();
                }
              })
          );
        } else if (this.userForm.value.Id == 0 && this.clientContacts.Id > 0) {
          this.subscriptions.push(
            this.clientSvc.addUser(userdetails).subscribe((res) => {
              if (!this.isClientUser) {
                this.clientContacts.IsUser = true;
                this.subscriptions.push(
                  this.clientSvc
                    .updateClientContact(this.clientContacts)
                    .subscribe()
                );
                this.toasterComponent.callToast();
                this.getClientContact();
              }
            })
          );
        } else {
          this.clientContacts.CreatedBy = BaseServices.UserId;

          this.clientContacts.IsUser = true;
          this.subscriptions.push(
            this.clientSvc
              .addClientContact(this.clientContacts)
              .subscribe((isContactAdded: any) => {
                this.clientId = isContactAdded.ClientId;
                if (isContactAdded) {
                  this.clientContacts.Id = isContactAdded.Id;
                  this.toasterComponent.callToast();
                  this.getClientContact();
                }
                userdetails.FeatureKey = isContactAdded.Id;
                this.subscriptions.push(
                  this.clientSvc.addUser(userdetails).subscribe((res) => {})
                );
              })
          );
        }
      }
    }
    this.addCategory = false;
    this.clear();
  }
  //#endregion

  /**
   * Set working Module
   * @param feature {any}
   */
  setCurrentModule(moduleId, moduleName, moduleDetail) {
    this.subscriptions.push(
      this.businessServices
        .getById(BaseServices.BusinessId)
        .subscribe((result: any) => {
          if (result) {
            let featureBase = BaseServices.getUserFeatures();
            let template = <any>JSON.parse(featureBase.FeaturesTemplate);
            featureBase.FeaturesTemplate = JSON.stringify(
              template.filter(
                (x: any) =>
                  (x.navigationRoles.indexOf('B') > -1 &&
                    x.IsFeature.indexOf('N') > -1) ||
                  (x.navigationRoles.indexOf('B') > -1 && x.IsDefault === 1)
              )
            );
            featureBase.Features = result.Features;
            featureBase.Type = 'business';
            featureBase.Details = result;
            if (moduleName == 'Project') {
              let baseFeatures = FeaturesBaseServices.getFeatureDetails(
                featureBase
              ).filter((x: any) => x.id == 16);
              sessionStorage.setItem(
                'workingModule',
                JSON.stringify(baseFeatures[0])
              );
              this.router.navigate(['./../../../project/details', moduleId], {
                relativeTo: this.route,
              });
            } else if (moduleName == 'KundeEL') {
              let baseFeatures = FeaturesBaseServices.getFeatureDetails(
                featureBase
              ).filter((x: any) => x.id == 24);
              sessionStorage.setItem(
                'workingModule',
                JSON.stringify(baseFeatures[0])
              );
              this.router.navigate(['./../../../kunde_EL/details', moduleId], {
                relativeTo: this.route,
              });
            } else if (moduleName == 'IK_CLIENT') {
              let baseFeatures = FeaturesBaseServices.getFeatureDetails(
                featureBase
              ).filter((x: any) => x.id == 36);
              sessionStorage.setItem(
                'workingModule',
                JSON.stringify(baseFeatures[0])
              );
              localStorage.setItem('contractNumber', moduleDetail.Number);
              localStorage.setItem('contractName', moduleDetail.Name);
              localStorage.setItem('clientName', this.clientForm.value.Name);
              localStorage.setItem('clientLogo', this.clientLogo);
              this.router.navigate(
                ['./../../../electro/details/' + moduleId + '/manual'],
                { relativeTo: this.route }
              );
            } else if (moduleName == 'Framework Agreement') {
              let baseFeatures = FeaturesBaseServices.getFeatureDetails(
                featureBase
              ).filter((x: any) => x.id == FeatureKey.FRAMEWORK_AGREEMENT);
              sessionStorage.setItem(
                'workingModule',
                JSON.stringify(baseFeatures[0])
              );
              this.router.navigate(
                ['./../../../framework-agreement/details', moduleId],
                { relativeTo: this.route }
              );
            } else if (moduleName == 'Service Agreement') {
              let baseFeatures = FeaturesBaseServices.getFeatureDetails(
                featureBase
              ).filter((x: any) => x.id == FeatureKey.SERVICE_AGREEMENT);
              sessionStorage.setItem(
                'workingModule',
                JSON.stringify(baseFeatures[0])
              );
              this.router.navigate(
                ['./../../../service-agreement/details', moduleId],
                { relativeTo: this.route }
              );
            }
          }
        })
    );
  }
  isclientNumUnique(control: FormControl) {
    const q = new Promise((resolve) => {
      setTimeout(() => {
        if (control.value === this.cachedClientNumber) {
          resolve(null);
        } else if ((control.value || '').trim().length === 0) {
          resolve({ whitespace: true });
        } else {
          this.subscriptions.push(
            this.businessServices.checkclientNum(control.value).subscribe(
              (x) => {
                if (!x) {
                  resolve(null);
                } else {
                  resolve({ clientNumberExists: true });
                }
              },
              () => {
                resolve({ clientNumberExists: true });
              }
            )
          );
        }
      }, 1000);
    });
    return q;
  }

  /**
   * @param event
   * validation for year
   */
  checkNumber(event: any): boolean {
    let k = event.keyCode;
    if (k < 48 || k > 57) {
      return false;
    }
    return true;
  }
  filesSelectImage(selectedFiles: any): void {
    this.uploadStart = true;
    this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(
      (file) => file
    );
    let formData = new FormData();
    formData.append('file', this.selectedFiles[0]);

    this.subscriptions.push(
      this.http
        .post(
          environment.BASE_URL +
            '/client/file/upload/employee-image/' +
            BaseServices.ApplicationId +
            '/' +
            this.businessId,
          formData
        )
        .map((response: any) => {
          this.uploadStart = false;
          let res = response;
          this.clientLogo = this.avatarName = res.Result.path;
        })
        .subscribe(
          (result) => {},
          (error) => console.log('Upload article Sign Error : ', error),
          () => console.log('Upload article  Sign Complete')
        )
    );
  }
  filesSelectContact(selectedFiles: any): void {
    this.uploadStartContact = true;
    this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(
      (file) => file
    );
    let formData = new FormData();
    formData.append('file', this.selectedFiles[0]);

    this.subscriptions.push(
      this.http
        .post(
          environment.BASE_URL +
            '/clientcontact/file/upload/employee-image/' +
            BaseServices.ApplicationId +
            '/' +
            this.businessId,
          formData
        )
        .map((response: any) => {
          this.uploadStartContact = false;
          let res = response;
          this.clientImageContact = this.avatarNameContact = res.Result.path;
        })
        .subscribe(
          (result) => {},
          (error) => console.log('Upload article Sign Error : ', error),
          () => console.log('Upload article  Sign Complete')
        )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
