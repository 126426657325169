<div class="main-content" *ngIf="!ContractorActive">
  <div class="card">
    <div class="card-body">
      <!--Contractor -->
      <div class="row">
        <div class="col-sm-6">
          <div class="page-title" translate>CONTRACTOR</div>
          <form [formGroup]="contractorForm" class="form">
            <div class="form-group">
              <label for="TypeofContractor" class="col-form-label">
                <span translate>TYPE_CONTRACTOR</span>
                <span class="required">*</span>
              </label>
              <div>
                <p-dropdown
                  [options]="contact"
                  [style]="{ width: '160px' }"
                  type="text"
                  formControlName="ContractorType"
                ></p-dropdown>
              </div>
            </div>
            <div class="form-group" *ngIf="contractorForm.controls['Name']">
              <label for="Name" class="col-form-label">
                <span translate>NAME</span>
                <span class="required">*</span>
              </label>
              <div>
                <input type="text" pInputText formControlName="Name" />
              </div>
              <control-messages
                [control]="contractorForm.controls['Name']"
              ></control-messages>
            </div>
            <div class="form-group">
              <label for="Address" class="col-form-label">
                <span translate>ORGANISATION_NUMBER</span>
              </label>
              <div>
                <input
                  type="text"
                  pInputText
                  formControlName="OrganizationNumber"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="Address" class="col-form-label">
                <span translate>ADDRESS</span>
              </label>
              <div>
                <textarea pInputTextarea formControlName="Address"></textarea>
              </div>
            </div>
            <div class="form-group">
              <label for="ZipCode" class="col-form-label">
                <span translate>ZIP_CODE</span>
                <control-messages
                  [control]="contractorForm.controls['Zip']"
                ></control-messages>
              </label>
              <div>
                <input
                  type="number"
                  pInputText
                  formControlName="Zip"
                  (keypress)="onMobileNumber($event, 20)"
                />
              </div>
              <div class="form-group">
                <label for="Location" class="col-form-label">
                  <span translate>LOCATION</span>
                </label>
                <div>
                  <input type="text" pInputText formControlName="Location" />
                </div>
              </div>
              <control-messages
                [control]="contractorForm.controls['Zip']"
              ></control-messages>
            </div>
            <div class="form-group">
              <label for="Mobile" class="col-form-label">
                <span translate>MOBILE</span>
              </label>
              <div class="phone-field">
                <p-dropdown
                  [options]="Mobile"
                  [formControl]="contractorForm.controls['Phonecode']"
                  [filter]="true"
                  [style]="{ width: '110px' }"
                >
                </p-dropdown>
                <input
                  type="number"
                  pInputText
                  formControlName="Mobile"
                  (keypress)="onMobileNumber($event, 20)"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="Telephone" class="col-form-label">
                <span translate>TELEPHONE</span>
              </label>
              <div>
                <input
                  type="number"
                  pInputText
                  formControlName="Telephone"
                  (keypress)="onMobileNumber($event, 20)"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="EmailId" class="col-form-label">
                <span translate>EMAIL</span>
                <span class="required">*</span>
              </label>
              <div>
                <input type="text" pInputText formControlName="Email" />
              </div>
              <control-messages
                [control]="contractorForm.controls['Email']"
              ></control-messages>
            </div>
            <div class="form-group">
              <label for="Comment" class="col-form-label">
                <span translate>COMMENT</span>
              </label>
              <div>
                <textarea pInputTextarea formControlName="Comment"></textarea>
              </div>
            </div>
            <div class="upload-file-container">
              <div class="form-group">
                <div class="upload-file-container">
                  <div class="upload-actions">
                    <input
                      type="file"
                      id="files"
                      class="hidden"
                      (change)="filesSelect($event)"
                    />
                    <label for="files" class="btn btn-outline-primary" translate
                      >UPLOAD_IMAGE</label
                    >
                  </div>
                  <div *ngIf="uploadStart">
                    <span translate>IMAGE_IS_UPLOADING</span>
                  </div>
                  <div *ngIf="contractorImage != null">
                    <img src="{{ contractorImage }}" alt="" width="200px" />
                  </div>
                </div>
              </div>
            </div>
            <div class="action-btns-wrapper" *ngIf="isEdit">
              <a
                routerLink="create"
                [routerLink]="['../../list']"
                class="btn btn-outline-secondary"
                routerLinkActive="active"
              >
                <span class="icon ic-xs icon-back"></span>
                <span translate>BACK_LIST</span>
              </a>
              <button
                class="btn btn-primary"
                type="button"
                [ngClass]="{ loading: loading }"
                (click)="saveContractor()"
                [disabled]="
                  !contractorForm.valid ||
                  loading ||
                  !contractorForm.controls['ContractorType'].value
                "
              >
                <span *ngIf="!loading" translate>SAVE</span>
                <span *ngIf="loading" translate>SAVE_PROCESS</span>
                <span *ngIf="loading" class="loader-icon"></span>
              </button>
            </div>
          </form>
        </div>
        <!--Contractor ends-->
        <!--Contractor contacts-->
        <div *ngIf="addcontractorContact" class="col-sm-6">
          <div class="page-title">
            <span translate>CONTRACTOR_CONTACTS</span>
          </div>
          <form [formGroup]="contractorContactsForm" class="form">
            <div
              class="form-group"
              *ngIf="contractorContactsForm.controls['ContactName']"
            >
              <label for="Name" class="col-form-label">
                <span translate>NAME</span>
                <span class="required">*</span>
              </label>
              <div>
                <input type="text" pInputText formControlName="ContactName" />
              </div>
              <control-messages
                [control]="contractorContactsForm.controls['ContactName']"
              >
              </control-messages>
            </div>
            <div *ngIf="isShowHSE">
              <div class="form-group">
                <label for="DateofBirth">
                  <span translate>DATE_BIRTH</span>
                </label>
                <div>
                  <p-calendar
                    dateFormat="dd/mm/yy"
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    [showIcon]="true"
                    formControlName="DateofBirth"
                    placeholder="{{ 'DATE_BIRTH' | translate }}"
                  ></p-calendar>
                </div>
              </div>
              <div class="form-group">
                <label for="Number">
                  <span translate>HSE_CARD_NUMBER</span>
                </label>
                <div>
                  <input
                    type="number"
                    pInputText
                    formControlName="HSECardNumber"
                    onpaste="return false;"
                    id="HSECardNumber"
                    (keypress)="onNumberChange($event, 20)"
                  />
                </div>
              </div>
            </div>

            <div
              class="form-group"
              *ngIf="contractorContactsForm.controls['ContactMobile']"
            >
              <label for="Mobile" class="col-form-label">
                <span translate>MOBILE</span>
              </label>
              <div class="phone-field">
                <p-dropdown
                  [options]="Mobile"
                  formControlName="Phonecode"
                  [filter]="true"
                  [style]="{ width: '110px' }"
                >
                </p-dropdown>
                <input
                  type="number"
                  pInputText
                  formControlName="ContactMobile"
                  (keypress)="onMobileNumber($event, 20)"
                />
              </div>
            </div>
            <div
              class="form-group"
              *ngIf="contractorContactsForm.controls['ContactEmailId']"
            >
              <label for="ContactEmailId" class="col-form-label">
                <span translate>EMAIL</span>
                <span class="required">*</span>
              </label>
              <div>
                <input
                  type="text"
                  pInputText
                  formControlName="ContactEmailId"
                />
              </div>
              <control-messages
                [control]="contractorContactsForm.controls['ContactEmailId']"
              >
              </control-messages>
            </div>
            <div class="form-group">
              <label for="Notification" class="col-form-label">
                <span translate>NOTIFICATION</span>
              </label>
              <div>
                <div class="checkbox">
                  <p-checkbox
                    label="{{ 'BY_SMS' | translate }}"
                    formControlName="IsSms"
                    [binary]="true"
                  >
                  </p-checkbox>
                </div>
                <div class="checkbox">
                  <p-checkbox
                    label="{{ 'BY_EMAIL' | translate }}"
                    formControlName="IsEmail"
                    [binary]="true"
                  ></p-checkbox>
                </div>
              </div>
            </div>
            <label *ngIf="approvedDateContact">
              <span translate>READ_APPLICATION</span>
              {{ approvedDateContact | date: "dd/MM/yyyy, h:mm" }}
            </label>
            <div class="upload-file-container">
              <div class="form-group">
                <div class="upload-file-container">
                  <div class="upload-actions">
                    <input
                      type="file"
                      id="upload"
                      class="hidden"
                      (change)="filesSelectContact($event)"
                    />
                    <label
                      for="upload"
                      class="btn btn-outline-primary"
                      translate
                      >UPLOAD_IMAGE</label
                    >
                  </div>
                  <div *ngIf="uploadStartContact">
                    <span translate>IMAGE_IS_UPLOADING</span>
                  </div>
                  <div *ngIf="contractorImageContact != null">
                    <img
                      src="{{ contractorImageContact }}"
                      alt=""
                      width="200px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="action-btns-wrapper" *ngIf="isEdit">
              <button
                class="btn btn-primary"
                type="submit"
                (click)="saveContractorContact()"
                [disabled]="!contractorContactsForm.valid"
              >
                <span translate>SAVE</span>
              </button>
              <button
                class="btn btn-primary"
                type="button"
                *ngIf="!userCreation && !canViewUser"
                (click)="saveUserContact()"
                translate
                [disabled]="!contractorContactsForm.valid"
              >
                SAVE_ADD_USER
              </button>
              <button
                class="btn btn-primary"
                type="button"
                *ngIf="userCreation && !canViewUser"
                (click)="saveUserContact()"
                translate
              >
                EDIT_USER
              </button>
            </div>
            <div class="table-view mtop-10">
              <!-- Table starts -->
              <p-table
                [value]="contractorcontacts"
                [rows]="10"
                [paginator]="true"
                [pageLinks]="3"
                [rowsPerPageOptions]="[5, 10, 20]"
              >
                <!-- Header -->
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="Name">{{ "NAME" | translate }}</th>
                    <th pSortableColumn="Mobile">{{ "MOBILE" | translate }}</th>
                    <th pSortableColumn="Email">{{ "EMAIL" | translate }}</th>
                    <th pSortableColumn="IsUser">{{ "USER" | translate }}</th>
                    <th></th>
                  </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-contractorcontact>
                  <tr>
                    <td>
                      <a
                        href="javascript:void(0)"
                        (click)="editContractorContact(contractorcontact)"
                        >{{ contractorcontact.Name }}</a
                      >
                    </td>
                    <td>
                      <span>{{ contractorcontact.Mobile }}</span>
                    </td>
                    <td>
                      <span>{{ contractorcontact.Email }}</span>
                    </td>
                    <td>
                      <span>{{
                        (contractorcontact.IsUser ? "Yes" : "No") | translate
                      }}</span>
                    </td>
                    <td class="col-button">
                      <button
                        type="button"
                        class="btn btn-icon"
                        title="{{ 'DELETE' | translate }}"
                        (click)="confirm(contractorcontact['Id'])"
                      >
                        <i class="icon ic-sm icon-trash"></i>
                      </button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <!-- Table ends -->
            </div>
          </form>
          <!--Contractor contacts ends-->
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="ContractorActive">
  <div class="row" style="padding: 1%; border-bottom: 1px solid">
    <div class="col-sm-8">
      <h3>
        <span translate>OUR_CONTRACTOR_PAGE </span>
        <span>{{ objBusineesDetails.Name }}</span>
      </h3>
    </div>
    <div class="col-sm-4">
      <div *ngIf="contractorImage != null">
        <img src="{{ contractorImage }}" alt="" width="200px" />
      </div>
    </div>
  </div>
  <div class="row" style="margin-top: 3%">
    <div class="col-sm-6">
      <div>
        <label class="col-sm-4" for="Username">
          <span translate>BUSINESS_NAME</span><span>&nbsp;&nbsp;:</span>
        </label>
        <span class="col-sm-6">
          {{ objBusineesDetails.Name }}
        </span>
      </div>
    </div>
    <div class="col-sm-6">
      <div>
        <label class="col-sm-4" for="Username">
          <span translate>SUB_CONTRACTOR_NAME</span><span>&nbsp;&nbsp;:</span>
        </label>
        <span class="col-sm-6">
          {{ subContractorData.Name }}
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div>
        <label class="col-sm-4" for="Username">
          <span translate>BUSINESS_ADDRESS</span><span>&nbsp;&nbsp;:</span>
        </label>
        <span class="col-sm-6">
          {{ objBusineesDetails.Address }}
        </span>
      </div>
    </div>
    <div class="col-sm-6">
      <div>
        <label class="col-sm-4" for="Username">
          <span translate>SUB_CONTRACTOR_ADDRESS</span
          ><span>&nbsp;&nbsp;:</span>
        </label>
        <span class="col-sm-6">
          {{ subContractorData.Address }}
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div>
        <label class="col-sm-4" for="Username">
          <span translate>CITY_ZIP</span><span>&nbsp;&nbsp;:</span>
        </label>
        <span class="col-sm-6">
          {{ objBusineesDetails.Zip }}
        </span>
      </div>
    </div>
    <div class="col-sm-6">
      <div>
        <label class="col-sm-4" for="Username">
          <span translate>CITY_ZIP</span><span>&nbsp;&nbsp;:</span>
        </label>
        <span class="col-sm-6">
          {{ subContractorData.Zip }}
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div>
        <label class="col-sm-4" for="Username">
          <span translate>PHONE</span><span>&nbsp;&nbsp;:</span>
        </label>
        <span class="col-sm-6">
          {{ objBusineesDetails.Phone }}
        </span>
      </div>
    </div>
    <div class="col-sm-6">
      <div>
        <label class="col-sm-4" for="Username">
          <span translate>PHONE</span><span>&nbsp;&nbsp;:</span>
        </label>
        <span class="col-sm-6">
          {{ subContractorData.Telephone }}
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div>
        <label class="col-sm-4" for="Username">
          <span translate>E-MAIL</span><span>&nbsp;&nbsp;:</span>
        </label>
        <span class="col-sm-6">
          {{ objBusineesDetails.Email }}
        </span>
      </div>
    </div>
    <div class="col-sm-6">
      <div>
        <label class="col-sm-4" for="Username">
          <span translate>E-MAIL</span><span>&nbsp;&nbsp;:</span>
        </label>
        <span class="col-sm-6">
          {{ subContractorData.Email }}
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <h4 translate style="margin-top: 5%">ASSIGNED_ACCESS</h4>
      <div class="table-view" *ngIf="moduleList ? true : false">
        <!-- Table starts -->
        <p-table
          [value]="moduleList"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "NUMBER" | translate }}</th>
              <th>{{ "NAME" | translate }}</th>
              <th>{{ "MODULES" | translate }}</th>
              <th>{{ "STATUS" | translate }}</th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-contractor>
            <tr>
              <td>
                <span>{{ contractor.Number }}</span>
              </td>
              <td>
                <a
                  (click)="setCurrentModule(contractor.Id)"
                  href="javascript:void(0);"
                  pTooltip="{{ contractor.Title }}"
                  tooltipPosition="right"
                  >{{ contractor.Title }}</a
                >
              </td>
              <td>
                <span>{{ contractor.Modules }}</span>
              </td>
              <td>
                <span>{{ contractor.Status | translate }}</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table ends -->
      </div>
    </div>
    <div class="col-sm-6">
      <h4 translate style="margin-top: 5%">OUR_USERS</h4>
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          [value]="contractorcontacts"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="Name">{{ "NAME" | translate }}</th>
              <th pSortableColumn="Mobile">{{ "MOBILE" | translate }}</th>
              <th pSortableColumn="Email">{{ "EMAIL" | translate }}</th>
              <th pSortableColumn="IsUser">{{ "USER" | translate }}</th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-contractor>
            <tr>
              <td>
                <span>{{ contractor.Name }}</span>
              </td>
              <td>
                <span>{{ contractor.Mobile }}</span>
              </td>
              <td>
                <span>{{ contractor.Email }}</span>
              </td>
              <td>
                <span>{{
                  (contractor.IsUser ? "Yes" : "No") | translate
                }}</span>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="4">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table ends -->
      </div>
    </div>
  </div>
</div>
<p-dialog
  header="{{ 'ADD_EDIT_USER' | translate }}"
  [(visible)]="addCategory"
  [style]="{ width: '800px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="userForm" *ngIf="userForm">
    <div class="row">
      <div class="col-sm-8">
        <div class="form">
          <div class="form-group" *ngIf="userForm.controls['Username']">
            <input type="text" formControlName="Id" hidden="true" />
            <label for="Name">
              <span translate>NAME</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="text"
                pInputText
                formControlName="Name"
                id="Name"
                class="ex-full-width"
              />
            </div>
            <control-messages
              [control]="userForm.controls['Name']"
            ></control-messages>
          </div>
          <div class="form-group" *ngIf="userForm.controls['Username']">
            <label for="Username">
              <span translate>USERNAME</span>
              <span class="required">*</span>
            </label>
            <div class="input-btn-group">
              <input
                type="text"
                style="width: 340px"
                pInputText
                formControlName="Username"
                id="Username"
                class="ex-full-width"
              />
              <button
                class="btn btn-primary-outline"
                type="button"
                (click)="refreshUser()"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </div>
            <control-messages
              [control]="userForm.controls['Username']"
            ></control-messages>
          </div>
          <div [hidden]="IsShow">
            <a
              routerLinkActive="active"
              href="javascript:void(0);"
              (click)="changeName()"
            >
              <span translate>OTHER_NAME</span>
            </a>
          </div>
          <div class="form-group">
            <label for="RoleId">
              <span translate>ADDITIONAL_ROLE</span>
              <span class="required">*</span>
            </label>
            <div>
              <p-dropdown
                [formControl]="userForm.controls['RoleId']"
                id="RoleId"
                [options]="role"
                [style]="{ width: '100%' }"
              ></p-dropdown>
            </div>
            <control-messages
              [control]="userForm.controls['RoleId']"
            ></control-messages>
          </div>
          <div class="form-group">
            <label for="Mobile">
              <span translate>MOBILE</span>
            </label>
            <div>
              <p-dropdown
                [options]="Mobile"
                [formControl]="userForm.controls['Phonecode']"
                filter="true"
                [style]="{ width: '100px' }"
              ></p-dropdown>
              <input
                style="width: 350px"
                type="number"
                pInputText
                formControlName="Mobile"
                id="mobilenum"
                (keypress)="onNumberChange($event, 20)"
                (input)="MobileEvent($event)"
              />
              <button class="btn btn-link" type="button" (click)="showDialog()">
                <span class="icon ic-sm icon-043"></span>
              </button>
            </div>
          </div>
          <div class="form-group">
            <label for="Email">
              <span translate>EMAIL</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="text"
                pInputText
                formControlName="Email"
                id="Email"
                class="ex-full-width"
              />
            </div>
            <control-messages
              [control]="userForm.controls['Email']"
            ></control-messages>
          </div>

          <div class="form-group" *ngIf="isNewUser">
            <label for="Password">
              <span translate>PASSWORD</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="password"
                pInputText
                formControlName="Password"
                id="Password"
                class="ex-full-width"
                validateEqual="RepeatPassword"
              />
            </div>
            <control-messages
              [control]="userForm.controls['Password']"
            ></control-messages>
          </div>

          <div class="form-group" *ngIf="isNewUser">
            <label for="Password">
              <span translate>REPEAT_PASSWORD</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="password"
                pInputText
                formControlName="RepeatPassword"
                id="RepeatPassword"
                class="ex-full-width"
                validateEqual="Password"
              />
            </div>
            <control-messages
              [control]="userForm.controls['RepeatPassword']"
            ></control-messages>
          </div>
          <div *ngIf="hidden">
            <label translate
              >YOUR_RANDOM_PASSWORD
              <span style="color: green"> {{ PasswordField }}</span>
            </label>
          </div>
          <div formArrayName="categories">
            <div
              class="checkbox"
              *ngFor="
                let def of userForm.controls['categories']['controls'];
                let i = index
              "
            >
              <label>
                <span formGroupName="{{ i }}">
                  <input
                    type="checkbox"
                    [checked]="userRightsData[i].isChecked"
                    formControlName="{{ userRightsData[i].id }}"
                    (change)="
                      onRightsCheckboxChange($event, userRightsData[i].id)
                    "
                    pTooltip="{{ userRightsData[i].name | translate }}"
                    tooltipPosition="right"
                  />
                  {{ userRightsData[i].name | translate }}
                </span>
              </label>
            </div>
          </div>
          <div class="action-btns-wrapper">
            <div class="row">
              <div class="col-sm-6">
                <button
                  class="btn btn-outline-primary"
                  *ngIf="isNewUser"
                  type="button"
                  (click)="generateRandomPassword()"
                >
                  <span translate>RANDOM_PASSWORD</span>
                </button>
                <button
                  class="btn btn-primary"
                  *ngIf="!isNewUser"
                  (click)="showResetPassword()"
                >
                  <span translate>RESET_PASSWORD</span>
                </button>
                <button
                  class="btn btn-primary"
                  type="button"
                  [disabled]="!userForm.valid"
                  (click)="saveUser()"
                >
                  <span translate>SAVE</span>
                </button>
              </div>
              <div class="col-sm-6">
                <button
                  class="btn btn-primary"
                  type="button"
                  (click)="saveAndSendSMS()"
                  [disabled]="!userForm.valid || isMobile"
                >
                  <span translate>SAVE_AND_SENDSMS</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
<p-dialog
  header="{{ 'RESET_PASSWORD' | translate }}"
  (onHide)="userClear()"
  [(visible)]="showPasswordResetDialog"
  [style]="{ width: '500px', height: '500px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="updatePasswordForm">
    <div formGroupName="passwordValidation">
      <div class="form-group">
        <label for="RepeatPassword">
          <span translate>NEW_PASSWORD</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="password"
            pInputText
            id="NewPassword"
            formControlName="Password"
            validateEqual="ConfirmPassword"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="RepeatPassword">
          <span translate>CONFIRM_PASSWORD</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="password"
            pInputText
            id="ConfirmPassword"
            formControlName="ConfirmPassword"
            required
            validateEqual="Password"
          />
        </div>
      </div>
      <control-messages
        [control]="updatePasswordForm.controls['passwordValidation']"
      ></control-messages>
      <div *ngIf="hidden">
        <label translate
          >YOUR_RANDOM_PASSWORD
          <span style="color: green"> {{ PasswordField }}</span>
        </label>
      </div>
    </div>
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="generateRandomPasswordReset()"
    >
      <span translate>RANDOM_PASSWORD</span>
    </button>
    <button
      class="btn btn-outline-primary"
      (click)="resetPassWord()"
      [disabled]="!updatePasswordForm.valid"
    >
      <span translate>SAVE</span>
    </button>
    <span style="color: red" *ngIf="passwordValidation"
      >YOUR_PASSWORD_DONOT_MATCH</span
    >
  </form>
</p-dialog>
<p-dialog
  header="{{ 'INFO' | translate }}"
  [(visible)]="dialogDisplay"
  [modal]="true"
  [style]="{ width: '500px', height: '500px' }"
  [resizable]="false"
  [draggable]="false"
>
  <span translate>
    PLEASE_ADD_YOUR_MOBILE_NUMBER_TO_RECEIVE_USERNAME_AND_PASSWORD
  </span>
</p-dialog>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<toaster-component></toaster-component>
