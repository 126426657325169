import { BaseServices } from './../../kuba.services';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import 'rxjs/add/operator/map';
import { Employee, EmployeeCourse } from '../models/index';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmployeeCourseServices {
  item: Object;
  constructor(private http: HttpClient) {}

  // List all employees
  list(businessId: number) {
    let result: EmployeeCourse;
    return this.http
      .get(environment.BASE_URL + '/employeecourses/' + businessId)
      .map((result: any) => result);
  }
  portalList(userId: number) {
    let result: EmployeeCourse;
    return this.http
      .get(environment.BASE_URL + '/PortalEmployeeCourses/' + userId)
      .map((result: any) => result);
  }
  // List all employees contracts
  listcontract(businessId: number) {
    let result: EmployeeCourse;
    return this.http
      .get(environment.BASE_URL + '/employeecontract/' + businessId)
      .map((result: any) => result);
  }
  portalListContract(userId: number) {
    let result: EmployeeCourse;
    return this.http
      .get(environment.BASE_URL + '/PortalEmployeeContract/' + userId)
      .map((result: any) => result);
  }

  // get employee course list
  getEmployeeCourseByEmployeeid(empId: number) {
    let result: EmployeeCourse;
    return this.http
      .get(environment.BASE_URL + '/coursesbyemployee/' + empId)
      .map(result => result);
  }
  // get employee course list
  getEmployeeContractByEmployeeid(empId: number, isEmployeecourses: any) {
    let result: EmployeeCourse;
    return this.http
      .get(
        environment.BASE_URL +
          '/coursesandcontractbyemployee/' +
          empId +
          '/' +
          isEmployeecourses
      )
      .map(result => result);
  }

  // retrive an employee for editing and View
  getById(id: number) {
    return this.http
      .get(environment.BASE_URL + '/employeecourse/' + id)
      .map((result: any) => result);
  }

  // add employee course
  add(data: any) {
    let body = JSON.stringify(data);
    return this.http
      .post(environment.BASE_URL + '/employeecourse', body)
      .map(result => result);
  }

  // update employee course
  update(ExistingEmployee: EmployeeCourse) {
    let result: EmployeeCourse;
    return this.http
      .put(environment.BASE_URL + '/employeecourse', ExistingEmployee)
      .map(result => result);
  }
  addcourseData(data: any) {
    return this.http
      .post(
        environment.BASE_URL + '/business/businesscourse/businesscourse',
        data
      )
      .map(result => result);
  }
  updateCourseData(data: any) {
    return this.http
      .put(
        environment.BASE_URL + '/business/businesscourse/businesscourse',
        data
      )
      .map(result => result);
  }
  deletecourseData(id: number) {
    let result: boolean;
    return this.http
      .delete(
        environment.BASE_URL +
          '/business/businesscourse/businesscourseDelete/' +
          id
      )
      .map(result => result);
  }
  deleteCourse(id: number) {
    let result: boolean;
    return this.http
      .delete(environment.BASE_URL + '/employeecourse/' + id)
      .map(result => result);
  }

  // bind dropdown Responsible
  getAllByResp(Id: number) {
    return this.http
      .get(environment.BASE_URL + '/lookup/' + Id)
      .map((result: any) => result);
  }
  // bind dropdown Course Name
  getAllByCourseName(Id: number, type: any) {
    return this.http
      .get(
        environment.BASE_URL +
          '/business/businesscourse/businesscourselist/' +
          Id +
          '/' +
          type
      )
      .map((result: any) => (result ? result : null));
  }
  // bind dropdown Contract Name
  getAllByContractName(Id: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/business/businesscourse/businesscontractlist/' +
          Id
      )
      .map((result: any) => (result ? result : null));
  }
  getAllByPortalCourseName(Id: number, type) {
    return this.http
      .get(
        environment.BASE_URL +
          '/business/businesscourse/portalbusinesscourselist/' +
          Id +
          '/' +
          type
      )
      .map(result => (result ? result : null));
  }
  getPortalCourse(Id: number, type: any) {
    return this.http
      .get(
        environment.BASE_URL +
          '/business/businesscourse/portalcourse/' +
          Id +
          '/' +
          type
      )
      .map((result: any) => (result ? result : null));
  }
  getallPortalCourse(portalId: number, businessId: number, type: any) {
    return this.http
      .get(
        environment.BASE_URL +
          '/business/businesscourse/portalallcourselist/' +
          portalId +
          '/' +
          businessId +
          '/' +
          type
      )
      .map(result => (result ? result : null));
  }
  getallPortalContractCourse(portalId: number, businessId: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/business/businesscourse/portalallcontractcourselist/' +
          portalId +
          '/' +
          businessId
      )
      .map(result => (result ? result : null));
  }
  getUserIdFromPortal(portalId: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/business/businesscourse/userIdFromPortal/' +
          portalId
      )
      .map((result: any) => (result ? result : null));
  }
  checkCourseName(courseName: any) {
    return this.http
      .get(
        environment.BASE_URL +
          '/business/businesscourse/checkcoursename/' +
          courseName
      )
      .map(result => result);
  }
  getEmployeeCourseDocument(courseId: number) {
    return this.http
      .get(environment.BASE_URL + '/employeecourse/' + courseId)
      .map(result => result);
  }
  private extractData(res: any) {
    let body = res.json(); // return body || {};
    return <Employee[]>res.json();
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
