import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { RegistrationList } from 'src/app/kuba/Registration/models/registration-list';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/Observable';

import { BaseServices } from './../../kuba.services';
import { RegistrationType } from './../models/registration-type';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RegistrationServices {
  constructor(private http: HttpClient) {}

  // List all registrations
  getAllByEach(id: number, key: string) {
    return this.http
      .get(
        `${environment.BASE_URL}/${BaseServices.apiRoute(
          key
        )}/registration/${id}`
      )
      .map((result: any) => result);
  }

  // add
  addRegistration(register: RegistrationList, key: number) {
    return this.http
      .post(
        `${environment.BASE_URL}/${BaseServices.apiRoute(key)}/registration`,
        register
      )
      .map(result => result);
  }

  // updating registration list
  updateRegistration(ExistingMember: RegistrationList, key: number) {
    return this.http
      .put(
        `${environment.BASE_URL}/${BaseServices.apiRoute(key)}/registration`,
        ExistingMember
      )
      .map(result => result);
  }

  // list all document
  getAllDocById(id: number) {
    return this.http
      .get(environment.BASE_URL + '/registration/' + id)
      .map(result => result);
  }
  getAllDocByDate(id: number, startdate: string, enddate: string) {
    return this.http
      .get(`${environment.BASE_URL}/registration/${id}/${startdate}/${enddate}`)
      .map(result => result);
  }

  // delete equipment from list
  deleteDoc(Id: any) {
    return this.http
      .delete(environment.BASE_URL + '/registration-document/' + Id)
      .map(result => result);
  }

  // edit register
  getRegisterById(id: number) {
    return this.http
      .get(environment.BASE_URL + '/registration/' + id)
      .map((result: any) => result);
  }

  // add registration to list
  add(data): Observable<any> {
    let body = JSON.stringify(data);
    return this.http
      .post(environment.BASE_URL + '/registration', body)
      .map(res => res);
  }

  // updating registration list
  update(ExistingMember: RegistrationList) {
    return this.http
      .put(environment.BASE_URL + '/registration', ExistingMember)
      .map(result => result);
  }

  // Delete registration from list
  deleteRegistration(Id) {
    return this.http
      .delete(environment.BASE_URL + '/registration/' + Id)
      .map(result => result);
  }

  // bind registration type
  getAllByRegType(id: number, key: string) {
    return this.http
      .get(
        `${environment.BASE_URL}/${BaseServices.apiRoute(
          key
        )}/registrationtype/${id}`
      )
      .map((result: any) => result);
  }

  // update registration Type
  updateRegType(existingCategory: RegistrationType, key: number) {
    return this.http
      .put(
        `${environment.BASE_URL}/${BaseServices.apiRoute(
          key
        )}/registrationtype`,
        existingCategory
      )
      .map(result => result);
  }

  // adding registration Type
  createRegType(newCategory: RegistrationType, key: number) {
    return this.http
      .post(
        `${environment.BASE_URL}/${BaseServices.apiRoute(
          key
        )}/registrationtype`,
        newCategory
      )
      .map(result => result);
  }

  // delete registration Type
  deleteRegType(Id: number) {
    let result: boolean;
    return this.http.delete(
      environment.BASE_URL + '/deleteregistrationtype/' + Id
    );
  }
  deleteRegistrationDocument(Id: number) {
    let result: boolean;
    return this.http.delete(
      environment.BASE_URL + '/registration-document/' + Id
    );
  }
}
