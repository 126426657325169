import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseServices } from '../kuba/kuba.services';
import { AdminUserService } from './../kuba/adminuser/services/adminuser.service';
import { EmployeeServices } from '../kuba/employees/services/employee.services';
import { HelperService } from 'src/app/_services/helper.service';
import { Rights } from 'src/app/_models';
import { Subscription } from 'rxjs';
import {AuthenticationService} from "../_services";

@Component({
  selector: 'user-profile',
  templateUrl: 'userProfile.component.html',
  styleUrls: ['userProfile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  //#region variable

  currentUser: any;
  profileImg: string;
  isRMode: boolean;
  userRights: any;
  isExits = false;
  rollId: number;
  private subscription: Subscription;
  //#endregion

  //#region constructor

  /**
   * constructor
   * @param route {ActivatedRoute}
   * @param router {Router}
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,

    private userService: AdminUserService,
    private employeeServices: EmployeeServices,
    private authenticationService: AuthenticationService
  ) {
    this.currentUser = BaseServices.userProfile;
    // fetching avatar from employee table only for employee
    if (this.currentUser.FeatureId === 8 && this.currentUser.RoleId !== 1) {  
      this.subscription = this.employeeServices
        .getById(this.currentUser.FeatureKey)
        .subscribe((result) => {
          if (result) {
            if (result.Avatar == null || result.Avatar == '') {
              this.profileImg = '/assets/icons/default_profile.jpg';
            } else {
              this.profileImg = result.Avatar;
            }
          }
        });
    }
  }

  //#endregion

  //#region page-event

  /**
   * init
   */
  ngOnInit() {
    this.rollId = +BaseServices.roleId;
    if (window.name === 'Remote') {
      this.isRMode = true;
    } else {
      this.isRMode = false;
    }

    if (this.currentUser.Avatar == null || this.currentUser.Avatar == '') {
      this.profileImg = '/assets/icons/default_profile.jpg';
    } else {
      this.profileImg = this.currentUser.Avatar;
    }
  }

  //#endregion

  //#region control-event

  /**
   * profile edit
   */
  userProfileEdit() {
    let role = BaseServices.UserRole;
    let bussinessId = BaseServices.BusinessId;
    let userid = BaseServices.UserId;
    if (role === 'Admin') {
      this.router.navigate(
        [
          './admin/' +
            BaseServices.ApplicationId +
            '/admin-user/edit/' +
            `${userid}`,
        ],
        { relativeTo: this.activatedRoute }
      );
    } else if (role === 'Portal') {
      let portalId = BaseServices.PortalId;
      if (BaseServices.PortalManager === false) {
        this.router.navigate(
          [
            './admin/' +
              BaseServices.ApplicationId +
              '/portal/edit/' +
              `${portalId}` +
              '/user',
          ],
          { relativeTo: this.activatedRoute }
        );
      } else {
        this.router.navigate(
          [
            './admin/' +
              BaseServices.ApplicationId +
              '/portal/edit/' +
              `${portalId}` +
              '/details',
          ],
          { relativeTo: this.activatedRoute }
        );
      }
    } else {
      this.router.navigate(
        [
          './../app/business/' +
            BaseServices.BusinessId +
            '/business/edit/' +
            BaseServices.BusinessId +
            '/user/edit/' +
            userid,
        ],
        { relativeTo: this.activatedRoute }
      );
    }
  }
  //#endregion

  public onLogout() {
    this.authenticationService.logout();
  }
}
