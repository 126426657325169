import { Module } from 'src/app/kuba/follow-ups/models/deviation';

import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { HttpHeaders, HttpHeaderResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { FeatureBase } from './features/models/features-base.model';
import { KubaComponent } from './kuba.component';
import { AuthenticationService } from '../_services';

@Injectable({
  providedIn: 'root'
})
export class KubaServices {
  private kubaComponent!: KubaComponent;

  // Observable string sources
  private setLangSource = new Subject<string>();
  userSettings: any;

  // Observable string streams
  getLanguage$ = this.setLangSource.asObservable();

  constructor(private http: HttpClient) {}

  // Method to set the reference to the KubaComponent
  setKubaComponent(kubaComponent: KubaComponent) {
    this.kubaComponent = kubaComponent;
  }

  // Method to reload the NavigationComponent via the KubaComponent
  reloadNavigationComponent() {
    if (this.kubaComponent) {
      this.kubaComponent.reloadNavigationComponent();
    }
  }

  // Service message commands
  setLanguage(lang: string) {
    this.setLangSource.next(lang);
  }

  getUserBase() {
    return this.http
      .get<HttpHeaderResponse>(environment.BASE_URL + '/user/base')
      .pipe(
        map((response: HttpHeaderResponse) => {
          response = response;
          return { userBase: response };
        })
      );
  }

  getAllLanguages() {
    return this.http
      .get<HttpHeaderResponse>(environment.BASE_URL + '/master/languages')
      .pipe(
        map((response: HttpHeaderResponse) => {
          response = response;
          return { languages: response };
        })
      );
  }

  getUserCustomSetting() {
    return this.http
      .get<HttpHeaderResponse>(environment.BASE_URL + '/user/customsetting')
      .pipe(
        map((response: HttpHeaderResponse) => {
          response = response;
          return { userLanguageTheme: response };
        })
      );
  }

  getUserFeatures() {
    return this.http
      .get<HttpHeaderResponse>(environment.BASE_URL + '/user/features')
      .pipe(
        map((response: any) => {
          response = response;
          return { userFeature: response };
        })
      );
  }
  getUserFeaturesByUserId(
    id: number,
    businessId: number,
    applicationId: number,
    portalId: number,
    roleId: number
  ) {
    return this.http
      .get(
        environment.BASE_URL +
          '/user/features/' +
          id +
          '/' +
          businessId +
          '/' +
          applicationId +
          '/' +
          portalId +
          '/' +
          roleId
      )
      .pipe(
        map((response: string) => {
          return response;
        })
      );
  }

  getUserNotifications() {
    return this.http
      .get<HttpHeaderResponse>(environment.BASE_URL + '/notifications/users')
      .pipe(
        map((response: HttpHeaderResponse) => {
          response = response;
          return { response: response };
        })
      );
  }

  public handleError(error: HttpHeaderResponse) {
    console.error(error);
    return throwError(error || 'Server error');
  }
}

@Injectable({
  providedIn: 'root'
})
export class BaseServices {
  public static userSettings: any = null;
  public static activeTree: any;
  public static articleId = 0;
  public static ses: any;
  static setUserSettings(userSettings: any) {
    this.userSettings = userSettings;
  }
  constructor(private authenticationService: AuthenticationService) {}
  static getUserSettings() {
    this.userSettings = sessionStorage.getItem('userSettings');
    return JSON.parse(this.userSettings);
  }
  static getLanguageId() {
    this.userSettings = sessionStorage.getItem('languageId');
    return this.userSettings;
  }

  static get userBase(): any {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase;
  }

  static get PortalManager(): boolean {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.IsPortalManager;
  }

  static get PortalBusinessListName(): string {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.PortalBusinessListName;
  }

  static get BusinessId(): number {
    if (window.name === 'Remote') {
      let businessId = +sessionStorage.getItem('RModeBusinessId')!;
      if (businessId > 0) {
        return +sessionStorage.getItem('RModeBusinessId')!;
      }
    }
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.BusinessId;
  }

  static get BusinessName(): string {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.BusinessName;
  }
  static get OrganizationNumber(): string {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.OrganizationNumber;
  }

  static get ApplicationId(): number {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.ApplicationId;
  }

  static get ApplicationName(): string {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.ApplicationName;
  }

  static get UserId(): number {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.UserId;
  }

  static get UserFeatureKey(): number {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.UserFeatureKey;
  }

  static get PortalId(): number {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.PortalId;
  }

  static get UserRole(): string {
    if (window.name === 'Remote') {
      return 'Editor';
    }
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.UserRole;
  }

  static get userTheme(): any {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userTheme;
  }

  static get userName(): string {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.UserName;
  }
  static get Name(): string {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.Name;
  }
  static get FeatureKey(): number {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userProfile.FeatureKey;
  }
  static get FeatureId(): number {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userProfile.FeatureId;
  }

  static get roleId(): string {
    if (window.name === 'Remote') {
      let businessId = +sessionStorage.getItem('RModeBusinessId')!;
      if (businessId > 0) {
        return '3';
      }
    }
    this.userSettings = this.getUserSettings();
    return `${this.userSettings.userProfile.RoleId}`;
  }

  static get appLanguages(): string | any {
    this.userSettings = this.getUserSettings();
    if (this.userSettings) {
      return this.userSettings.languages;
    }
  }

  static get userLanguage(): string | any {
    this.userSettings = this.getUserSettings();
    if (this.userSettings) {
      return this.userSettings.userLanguage;
    }
  }
  static get userLanguageId(): number {
    this.userSettings = this.getLanguageId();
    return this.userSettings;
  }

  static get userFeatures(): any {
    this.userSettings = this.getUserSettings();
    return JSON.parse(this.userSettings.userFeature.Features);
  }

  static get userProfile(): string {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userProfile;
  }

  static get getActiveTree(): any {
    return this.activeTree;
  }

  static get getArticleId(): any {
    return this.articleId;
  }
  static getUserFeatures(): FeatureBase {
    this.userSettings = this.getUserSettings();
    if (this.userSettings.userFeature) {
      return this.userSettings.userFeature;
    }
    return null!;
  }

  static getUserRights() {
    this.userSettings = this.getUserSettings();
    if (this.userSettings.userFeature.UserRights) {
      return this.userSettings.userFeature.UserRights;
    }
    return null;
  }

  static setSessionData(sessionName: string, data: any) {
    let session = sessionStorage.getItem(sessionName);
    if (session) {
      sessionStorage.removeItem(sessionName);
    }
    sessionStorage.setItem(sessionName, data);
  }

  static setActiveTreeNode(data: any) {
    this.activeTree = data;
  }

  static setArticleId(id: number) {
    this.articleId = id;
  }

  static appendSessionData(sessionName: string, data: any) {
    let session = sessionStorage.getItem(sessionName);
    if (session) {
      sessionStorage.removeItem(sessionName);
    }
    sessionStorage.setItem(sessionName, data);
  }

  static apiRoute(key: any): string {
    let apiRoute = '';
    switch (key) {
      case 'PROJECT':
        apiRoute = 'project';
        break;
      case 'FDV':
        apiRoute = 'firedeviation';
        break;
      case 'IC':
        apiRoute = 'internalcontrol';
        break;
      case 'OS':
        apiRoute = 'othersystems';
        break;
      case 'QS':
        apiRoute = 'qualitysystem';
        break;
      case 'FS':
        apiRoute = 'foodsafety';
        break;
      case 'VEHICLE':
        apiRoute = 'vehicle';
        break;
      case 'SA':
        apiRoute = 'serviceAgreement';
        break;
      case 'FRAMEWORK':
        apiRoute = 'framework';
        break;
      case 'KUNDE_EL':
        apiRoute = 'kundeEL';
        break;
      case 'KUBA_CONTROL':
        apiRoute = 'kubacontrol';
        break;
    }
    return apiRoute;
  }

  static getFeatureId(key: any): number {
    let featureId = 1; // follow up
    switch (key) {
      case 'PROJECT':
        featureId = Module.PROJECT;
        break;
      case 'FDV':
        featureId = Module.FIREDEVIATION;
        break;
      case 'IC':
        featureId = Module.INTERNALCONTROL;
        break;
      case 'OS':
        featureId = Module.OTHER_SYSTEMS;
        break;
      case 'QS':
        featureId = Module.QUALITY_SYSTEMS;
        break;
      case 'FS':
        featureId = Module.FOODSAFETY;
        break;
      case 'VEHICLE':
        featureId = Module.VEHICLE;
        break;
      case 'SA':
        featureId = Module.SERVICEAGREEMENT;
        break;
      case 'FRAMEWORK':
        featureId = Module.FRAMEWORKAGREEMENT;
        break;
      case 'KUNDE_EL':
        featureId = Module.Kunde_EL;
        break;
      case 'KUBA_CONTROL':
        featureId = Module.KUBA_CONTROL;
        break;
    }
    return featureId;
  }

  static checkUserRights(userRightId: number, roleFilter: string) {
    let currentUserRole = BaseServices.UserRole;
    if (currentUserRole === roleFilter) {
      let data = this.getUserRights();
      let userRights = JSON.parse(data);
      return this.checkRightsForAccess(userRights, userRightId);
    }
    return true;
  }

  static checkRightsForAccess(rightsData: any, rightsId: number): boolean {
    let userRight = false;
    if (rightsData && rightsData.length > 0) {
      for (let x = 0; x < rightsData.length; x++) {
        let element = rightsData[x];
        let id = +element.id;
        if (id === rightsId) {
          return element.isChecked;
        }
      }
    }
    return userRight;
  }
  static handleError(error: HttpHeaderResponse) {
    console.error('ApiService::handleError', error);
    if (error.status === 401) {
      let appSettings = JSON.parse(sessionStorage.getItem('appSettings')!);
      if (appSettings) {
        window.location.href = appSettings.BaseUrl + '/login';
      }
      window.location.href = '/login';
    }
    return throwError(error);
  }

  /**
   * generate file
   * @param reportData {any}
   * @param columnNames {any}
   * @param type {any}
   */
  generateExportFile(
    reportData: any,
    columnNames: any,
    type: any,
    headerName: any,
    printMode: any
  ) {
    let dynamicType: any;
    if (type === 'PDF') {
      dynamicType = 'application/pdf';
    } else if (type === 'EXCEL') {
      dynamicType =
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (type === 'WORD') {
      dynamicType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    let myData = JSON.stringify({ data: reportData, columnNames: columnNames });
    let url = `${environment.BASE_URL}/export/${type}/${headerName}/${printMode}`;
    let crfToken = this.authenticationService.getCsrfToken();
    return Observable.create((observer: any) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Logo-Id', 'ab064fd4-9b4a-4e70-972d-dafdaceb2aba');
      xhr.setRequestHeader('X-XSRF-TOKEN', crfToken);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }

  static userCultureInfo(languageName?: string): string {
    this.userSettings = this.getUserSettings();
    let cultureInfo = '';
    let langKey = languageName
      ? languageName
      : this.userSettings.userLanguage.Id;
    switch (langKey) {
      case 1:
      case 'en':
        cultureInfo = 'en-GB';
        break;
      case 2:
      case 'no':
        cultureInfo = 'no-NO';
        break;
      case 3:
      case 'sv':
        cultureInfo = 'sv-SE';
        break;
      case 4:
      case 'pl':
        cultureInfo = 'pl-PL';
        break;
    }
    return cultureInfo;
  }

  /*
    Util method to serialize a string to a specific Type
    */
  static getSerialized<T>(arg: any): T {
    return <T>JSON.parse(JSON.stringify(arg));
  }

  static get IsTOCApproved(): boolean {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.IsTOCApproved;
  }

  static get BusDepartment(): number {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.BusinessDepartmentId;
  }
}
