<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<div class="button-container">
  <button (click)="toggleView()" *ngIf="!isAdminMode">
    <svg
      *ngIf="!isGridView"
      class="view-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path
        d="M120-520v-320h320v320H120Zm0 400v-320h320v320H120Zm400-400v-320h320v320H520Zm0 400v-320h320v320H520ZM200-600h160v-160H200v160Zm400 0h160v-160H600v160Zm0 400h160v-160H600v160Zm-400 0h160v-160H200v160Zm400-400Zm0 240Zm-240 0Zm0-240Z"
      />
    </svg>
    <svg
      *ngIf="isGridView"
      class="view-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path
        d="M280-600v-80h560v80H280Zm0 160v-80h560v80H280Zm0 160v-80h560v80H280ZM160-600q-17 0-28.5-11.5T120-640q0-17 11.5-28.5T160-680q17 0 28.5 11.5T200-640q0 17-11.5 28.5T160-600Zm0 160q-17 0-28.5-11.5T120-480q0-17 11.5-28.5T160-520q17 0 28.5 11.5T200-480q0 17-11.5 28.5T160-440Zm0 160q-17 0-28.5-11.5T120-320q0-17 11.5-28.5T160-360q17 0 28.5 11.5T200-320q0 17-11.5 28.5T160-280Z"
      />
    </svg>
  </button>
</div>

<div
  *ngIf="isGridView"
  class="grid-view py-3"
  [ngStyle]="{
    'grid-template-columns':
      manualList.length <= 3
        ? 'repeat(3, minmax(0, 1fr))'
        : 'repeat(auto-fill, minmax(200px, 1fr))'
  }"
>
  <a
    *ngFor="let manual of manualList"
    [routerLink]="[
      '../view',
      manual['AppManualId'],
      manual['Title'],
      manual['PortalId'],
      manual['BusinessId']
    ]"
    class="manual-link"
  >
    <div
      class="manual-box"
      [ngStyle]="{
        'background-image': manual.CoverImg
          ? 'url(' + manual.CoverImg + ')'
          : 'url(/assets/images/manual-icon.svg)',
        height: manualList.length <= 3 ? '600px' : '200px'
      }"
    >
      <div
        class="manual-title"
        [ngStyle]="{
          'font-size': manualList.length <= 3 ? '1.5em' : '1em',
          padding: manualList.length <= 3 ? '8px 0' : '4px 0'
        }"
      >
        {{ manual.Title | translate }}
      </div>
    </div>
  </a>
</div>

<div class="table-view" *ngIf="!isGridView">
  <!-- Table starts -->
  <p-table
    [value]="manualList"
    #approvaltable
    [rows]="10"
    [paginator]="true"
    expandableRows="true"
    [pageLinks]="3"
    [rowsPerPageOptions]="[5, 10, 20]"
    #dt
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
  >
    <!-- Header -->
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="Title">
          {{ safetyManual | translate }} <p-sortIcon field="Title"></p-sortIcon>
        </th>
        <th pSortableColumn="CreatedOn">
          {{ CreatedDate | translate }}
          <p-sortIcon field="CreatedOn"></p-sortIcon>
        </th>
        <th pSortableColumn="CreatedByName">
          {{ CreatedBy | translate }}
          <p-sortIcon field="CreatedByName"></p-sortIcon>
        </th>
        <th *ngIf="manualType === 'portal'" pSortableColumn="LevelKeyId">
          {{ "PORTAL_TITLE" | translate }}
        </th>
        <th *ngIf="manualType === 'business'" pSortableColumn="LevelKeyId">
          {{ "BUSINESS_NAME" | translate }}
        </th>
        <th *ngIf="roleId === 1 || roleId === 2">{{ status | translate }}</th>
        <th *ngIf="isAdminMode">{{ "OPTIONS" | translate }}</th>
      </tr>
      <tr>
        <th>
          <input
            pInputText
            type="text"
            (input)="dt.filter($any($event.target)?.value, 'Title', 'contains')"
            placeholder=""
            class="p-column-filter"
          />
        </th>
        <th></th>
        <th></th>
        <th *ngIf="manualType === 'portal'">
          <p-dropdown
            [options]="portalList"
            (onChange)="dt.filter($event.value, 'LevelKeyId', 'equals')"
            styleClass="p-column-filter"
            placeholder="Select"
            [showClear]="true"
          >
            <ng-template let-portal pTemplate="item">
              {{ portal.label | translate }}
            </ng-template>
          </p-dropdown>
        </th>
        <th *ngIf="manualType === 'business'">
          <p-dropdown
            [options]="businessList"
            (onChange)="dt.filter($event.value, 'LevelKeyId', 'equals')"
            styleClass="p-column-filter"
            placeholder="Select"
            [showClear]="true"
          >
            <ng-template let-business pTemplate="item">
              {{ business.label | translate }}
            </ng-template>
          </p-dropdown>
        </th>
        <th *ngIf="roleId === 1 || roleId === 2">
          <p-dropdown
            [options]="statusList"
            (onChange)="dt.filter($event.value, 'Status', 'equals')"
            styleClass="p-column-filter"
            placeholder="Select"
            [showClear]="true"
          >
            <ng-template let-status pTemplate="item">
              {{ status.label | translate }}
            </ng-template>
          </p-dropdown>
        </th>
        <th *ngIf="isAdminMode"></th>
      </tr>
    </ng-template>
    <!-- Body Content -->
    <ng-template pTemplate="body" let-manual>
      <tr>
        <td>
          <a
            [routerLink]="[
              '../view',
              manual['AppManualId'],
              manual['Title'],
              manual['PortalId'],
              manual['BusinessId']
            ]"
            >{{ manual.Title | translate }}</a
          >
        </td>
        <td>{{ manual.CreatedOn | date: "dd/MM/yyyy" }}</td>
        <td>{{ manual.CreatedByName }}</td>
        <td *ngIf="manualType === 'portal'">{{ manual.LevelKeyName }}</td>
        <td *ngIf="manualType === 'business'">{{ manual.LevelKeyName }}</td>
        <td *ngIf="roleId === 1 || roleId === 2">
          <span
            [ngClass]="manual.Status != '1' ? 'text-danger' : 'text-success'"
          >
            {{
              manual.Status
                | status: ["DELETED", "ACTIVE", "INACTIVE"]
                | translate
            }}</span
          >
        </td>
        <td class="col-button" *ngIf="isAdminMode">
          <a
            (click)="editManual(manual)"
            *ngIf="isAdminMode"
            class="btn btn-icon"
          >
            <i
              class="icon ic-sm icon-pencil-tip"
              pTooltip="{{ EditManual | translate }}"
              tooltipPosition="top"
            ></i>
          </a>

          <a
            *ngIf="!manual['isCommon']"
            class="btn btn-icon"
            (click)="deleteProject(manual.Id, manual.Title)"
          >
            <i
              class="icon ic-sm icon-trash"
              pTooltip="{{ DeleteManual | translate }}"
              tooltipPosition="top"
            ></i>
          </a>
        </td>
      </tr>
    </ng-template>
    <!-- Empty message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td colspan="3">
          {{ "NO_RECORDS_FOUND" | translate }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-article>
      <!-- Table starts -->
      <p-table [value]="article.ApprovedArticle" [rows]="5" [responsive]="true">
        <!-- Header -->
        <ng-template pTemplate="header">
          <tr>
            <th></th>
            <th>{{ "ARTICLE_NAME" | translate }}</th>
            <th>{{ "STATUS" | translate }}</th>
            <th>{{ "APPROVED_BY" | translate }}</th>
            <th>{{ "APPROVED_DATE" | translate }}</th>
          </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-mannual>
          <tr>
            <td>
              <ecpl-article-viewer
                [businessMode]="busMode"
                [articleName]="mannual.articleName"
                [(articleSrc)]="mannual.content"
                [id]="mannual.id"
                [adminUsers]="adminUsers"
                (saveChanges)="saveEmitter($event)"
                [editMode]="false"
              >
              </ecpl-article-viewer>
            </td>
            <td>{{ mannual.content.article.articleName }}</td>
            <td>
              <span
                [style.color]="
                  mannual.content.article.approvedStatus === 'APPROVED'
                    ? 'Green'
                    : 'red'
                "
                >{{ mannual.content.article.approvedStatus | translate }}</span
              >
            </td>
            <td>{{ mannual.content.article.approvedBy }}</td>
            <td>
              <span
                [style.color]="
                  mannual.content.article.approvedDate !== '' ? 'Green' : 'red'
                "
                >{{ mannual.content.article.approvedDate }}</span
              >
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- Table ends -->
    </ng-template>
  </p-table>
  <!-- Table ends -->
</div>
<p-dialog
  header="{{ 'NOTIFICATION' | translate }}"
  [(visible)]="isRightsEnabled"
  [style]="{ width: '600px' }"
  [resizable]="false"
  [draggable]="false"
>
  <span translate> DONT_HAVE_RIGHTS </span>
</p-dialog>

<p-dialog
  header="{{ 'APPROVE_ALL_ARTICLES' | translate }}"
  [(visible)]="displayDialogForApproval"
  [style]="{ width: '350px' }"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
  [modal]="true"
  [closable]="true"
>
  <span translate>APPROVED_CONTENT</span> : {{ manualTitle }}
  <br />
  <br />
  <span translate>APPROVED_INFO</span>
  <br />
  <br />
  <span translate>APPROVED_ALERT</span>
  <br />
  <span translate>SELECT_APPROVED_DATE</span> :
  <my-date-picker
    name="approveddate"
    [options]="eventDateOptions"
    [(ngModel)]="approvedDate"
    (dateChanged)="onDateChanged($event)"
  >
  </my-date-picker>
  <div class="action-btns-wrapper">
    <button
      [disabled]="!approvedDate"
      type="button"
      class="btn btn-primary"
      icon="pi-check"
      (click)="onClickYes()"
    >
      <span translate>SWITCH_YES</span>
    </button>
    <button
      type="button"
      class="btn btn-primary"
      icon="pi-check"
      (click)="onClickCancel()"
    >
      <span translate>CANCEL</span>
    </button>
  </div>
</p-dialog>

<div *ngIf="manualList.length < 1" class="no-manuals-message">
  <span translate>NO_MANUALS_ASSIGNED</span>
</div>

<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>
