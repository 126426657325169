import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { FeatureKey } from 'src/app/_models';
import { FeaturesBaseServices } from './../../features/services/features-base.services';
import { Rights } from './../../../_models/feature';
import { BaseServices } from './../../kuba.services';
import { EmployeeServices } from './../services/employee.services';
import { HelperService } from '../../../_services/helper.service';
import { BusinessPortalService } from 'src/app/kuba/portals/services/business-portal.services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'employee-tabs',
    templateUrl: 'employee-tabs.component.html'
})
export class EmployeeTabComponent implements OnInit {

    //#region variables
    employeeView = true;
    name = '';
    employeeId: number;
    enableLinks = false;
    title: string;
    newEmpoyee: boolean;
    rightsData: { id: number, access: boolean }[];
    enabledAdditionalFeatures: any;
    @Input() parentRoute: string;
    employeeDepartmentId: number;
    loginEmployeeDepartmentId: number;
    IsCommonPortalCourseNames = true;
    eId : any;
    employeeTitle: string = 'EMPLOYEES';
    employeeName : string = '';
    private subscriptions: Subscription[] = [];
     //#endregion

    //#region constructor
    /**
     * constructor
     * @param router {Router}
     * @param route {ActivatedRoute}
     * @param employeeServices {EmployeeServices}
     */
    constructor(private route: ActivatedRoute,
        private employeeServices: EmployeeServices,
        private featuresBaseServices: FeaturesBaseServices,
        private businessPortalService: BusinessPortalService) {

        this.employeeId = this.route.snapshot.params['id'];
        this.subscriptions.push(this.featuresBaseServices.getEmployeeNavigationAccess$.subscribe(isEditMode => {
            if (!isEditMode) {
                let allAdditionalFeatures = JSON.parse(sessionStorage.getItem('workingModule')!).children;
                let swapModule = allAdditionalFeatures.filter((e:any) => e.translationKey == 'DOCUMENTED_TRAINING');
                if(swapModule.length > 0){
                    allAdditionalFeatures = allAdditionalFeatures.filter((e:any) => e.translationKey != 'DOCUMENTED_TRAINING');
                    allAdditionalFeatures.push(swapModule[0]);
                }
                this.enabledAdditionalFeatures = allAdditionalFeatures.filter((element:any) => (element.checked || element.IsDefault)&& element.id !== 119);
                if(BaseServices.roleId == ('4' && '5')){
                    this.enabledAdditionalFeatures = allAdditionalFeatures.filter((element:any) => (element.checked || element.IsDefault)&& element.id !== 119 && element.id !== 122);
                }
                if(BaseServices.roleId == '4'){
                    this.enabledAdditionalFeatures = this.enabledAdditionalFeatures.filter((x:any) => x.id !=198 && x.id != 196 && x.id != 203)
                }
                if (BaseServices.roleId === '2') {
                    this.enabledAdditionalFeatures.forEach((element:any) => {
                        if (element.id === FeatureKey.EMPLOYEES) {
                            element.translationKey = 'CUSTOMER_EMPLOYEES';
                        }
                    });
                }
                this.employeeView = false;
                this.enableLinks = false;
                this.employeeId = 0;
            } else {
                if (route.snapshot.parent!.data['parent'] === 'MYPAGE') {
                    this.employeeId = +BaseServices.FeatureKey;
                } else {
                    this.subscriptions.push(this.route.firstChild.params.subscribe((params: Params) => {
                        this.employeeId = +params['eid'];
                    }));
                }
                this.employeeView = true;
            }
            if (this.employeeId > 0) {
                this.enableLinks = true;
                let leftNav = JSON.parse(sessionStorage.getItem('leftNav')!);
                let filteredTemplate = HelperService.filterTree('N', leftNav.filter((x:any) => x.id === FeatureKey.MY_PAGE), 'IsFeature');
                let allAdditionalFeatures: any;
                let rightsCheck: boolean;
                if (filteredTemplate.length > 0) {
                    allAdditionalFeatures = filteredTemplate[0].children;
                    if (+BaseServices.roleId === 5) {
                        let userRightsId = Rights.EDIT_DEPARTMENT_EMPLOYEE_RIGHTS;
                        let userRights = BaseServices.getUserRights();
                        let data = JSON.parse(userRights);
                        rightsCheck = HelperService.checkRightsData(
                            data,
                            userRightsId
                        );
                        this.enabledAdditionalFeatures = null;
                        this.subscriptions.push(this.employeeServices.getById(this.employeeId).subscribe((result: any) => {
                            if (result) {
                                this.employeeDepartmentId = result.BusinessDepartmentId
                                if (this.loginEmployeeDepartmentId > 0 && this.employeeDepartmentId > 0 &&
                                    this.loginEmployeeDepartmentId === this.employeeDepartmentId && rightsCheck === true) {
                                    this.enabledAdditionalFeatures =
                                        allAdditionalFeatures.filter((element:any) => element.checked || element.IsDefault);
                                } else {
                                    let userbasefeatures = allAdditionalFeatures.filter((element:any) => element.checked || element.IsDefault);
                                    this.enabledAdditionalFeatures = userbasefeatures.filter((x:any) => x.id === 129 || x.id === 125)
                                    if (this.employeeId === +BaseServices.FeatureKey) {
                                        this.enabledAdditionalFeatures =
                                            allAdditionalFeatures.filter((element:any) => element.checked || element.IsDefault);
                                    }
                                }
                            }
                        }))
                    } else {
                        this.enabledAdditionalFeatures = allAdditionalFeatures.filter((element:any) => element.checked || element.IsDefault);
                    }
                }
            }
            
            this.subscriptions.push(this.route.firstChild.params.subscribe((params: Params) => {
                this.eId = +params['eid'];
            }));
            // document training
            if(this.enabledAdditionalFeatures != null) {
                             this.enabledAdditionalFeatures.forEach((x:any) => {
                    if(x.name == "DocumentedTraining" || x.name == "Documented Training") {
                    if(this.employeeId > 0)
                        x.linkText = 'documented-training/multi-doc/' + this.employeeId;
                     else 
                        x.linkText = 'documented-training/multi-doc/' + this.employeeId + '/1';
                   }
                   if(x.name == "EmployeesPrivateDocuments") {
                     x.linkText =  "private-doc/multi-doc/" + this.employeeId +"/1";
                }
                 
                });
            }

            if(JSON.parse(sessionStorage.getItem('workingModule')!).name == 'Employees') {
                this.employeeTitle = 'EMPLOYEES';
                this.employeeName = '';
            }
            if(this.eId > 0) {
                this.employeeTitle = 'EMPLOYEE' 
                this.subscriptions.push(this.employeeServices.getById(this.eId).subscribe(result => {
                    if(result) {
                        this.employeeName = ' - ' + result.Name;
                    }
                }));
            }
            //endregion            
        }));
    }
    //#endregion

    //#region page-events
    /**
     * page init event
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.navigationRights();
        if(this.eId > 0) {
            this.employeeTitle = 'EMPLOYEE' 
            this.subscriptions.push(this.employeeServices.getById(this.eId).subscribe(result => {
                if(result) {
                    this.employeeName = ' - ' + result.Name;
                }
            }));
        }
    }

    //#endregion

    //#region methods
    /**
     * set employee title
     * @param employeeId {number}
     */
    setEmployeeTitle(employeeId?: number) {
        if (employeeId && employeeId > 0) {
            this.subscriptions.push(this.employeeServices
                .getById(employeeId)
                .subscribe(res => {
                    this.name = res.Name;
                }));
        } else {
            this.name = '';
        }
    }

    /**
     * disable tabs based upon the rights
     */
    navigationRights() {
        let role = BaseServices.UserRole;
        if (role === 'User') {
            this.rightsData = [
                { id: FeatureKey.EMPLOYEE_LEAVE, access: BaseServices.checkUserRights(Rights.APPROVE_LEAVE, role) },
                { id: FeatureKey.EMPLOYEE_SICKNESS_ABSENCE, access: BaseServices.checkUserRights(Rights.APPROVE_ABSENSE, role) },
                { id: FeatureKey.EMPLOYEE_TIMETABLE, access: BaseServices.checkUserRights(Rights.APPROVE_TIMETABLE, role) }, 
                { id: FeatureKey.DOCUMENTED_TRAINING, access: BaseServices.checkUserRights(Rights.DOCUMENTED_TRAINING_RIGHTS, role)},
                { id: FeatureKey.APPROVAL_DOCUMENTS, access: BaseServices.checkUserRights(Rights. APPROVAL_DOCUMENT_RIGHTS, role)}
            ];
        } else if (role === 'Guest') {
            this.title = 'EMPLOYEES';
            this.rightsData = [
                { id: FeatureKey.EMPLOYEE_LEAVE, access: false },
                { id: FeatureKey.EMPLOYEE_SICKNESS_ABSENCE, access: false },
                { id: FeatureKey.EMPLOYEE_TIMETABLE, access: false },
                { id: FeatureKey.EMPLOYEE_COURSE_DETAILS, access: false },
                { id: FeatureKey.EMPLOYEE_FIRST_OF_KIN, access: false }
            ];
        } else if (role === 'Portal') {
            if (+BaseServices.PortalId) {
                let portalId = +BaseServices.PortalId;
                this.subscriptions.push(this.businessPortalService.getById(portalId).subscribe(
                    result => {
                        if (result) {
                            this.title = 'OUR_EMPLOYEES';
                            this.rightsData = [
                                { id: FeatureKey.EMPLOYEE_LEAVE, access: false },
                                { id: FeatureKey.EMPLOYEE_SICKNESS_ABSENCE, access: true },
                                { id: FeatureKey.EMPLOYEE_TIMETABLE, access: false },
                                { id: FeatureKey.EMPLOYEE_FIRST_OF_KIN, access: false }
                            ];
                        }
                    }
                ));
            }
            this.title = 'OUR_EMPLOYEES';
            this.rightsData = [
                { id: FeatureKey.EMPLOYEE_LEAVE, access: false },
                { id: FeatureKey.EMPLOYEE_SICKNESS_ABSENCE, access: true },
                { id: FeatureKey.EMPLOYEE_TIMETABLE, access: false },
                { id: FeatureKey.EMPLOYEE_FIRST_OF_KIN, access: false }
            ];
        } else if (role === 'Admin' || role === 'Editor') {
            this.title = 'EMPLOYEES';
            this.rightsData = [
                { id: FeatureKey.EMPLOYEE_LEAVE, access: true },
                { id: FeatureKey.EMPLOYEE_SICKNESS_ABSENCE, access: true },
                { id: FeatureKey.EMPLOYEE_TIMETABLE, access: true },
                { id: FeatureKey.EMPLOYEE_COURSE_DETAILS, access: true },
                { id: FeatureKey.EMPLOYEE_FIRST_OF_KIN, access: true },
            ];
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
