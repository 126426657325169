<div class="main-content">
  <div class="main-heading">
    <button class="btn btn-icon" (click)="pushToBack()">
      <span class="icon ic-md icon-back"></span>
    </button>
    <span>{{ manualName }}</span>
    <span *ngIf="editMode" class="label-mode"
      >[ <span translate>EDIT_MODE</span>]</span
    >

    <div
      class="page-actions text-right"
      *ngIf="isEditManuals && userRole !== 'Guest'"
    >
      <!-- New button for soft-delete -->
      <button
        class="btn btn-outline-danger"
        *ngIf="editMode && coverImage"
        (click)="softDeleteCoverImage()"
      >
        <span translate>DELETE_COVER_IMAGE</span>
      </button>

      <input
        type="file"
        id="files"
        hidden
        *ngIf="editMode"
        (change)="myUploader($event)"
      />
      <label
        for="files"
        class="btn btn-outline-primary"
        *ngIf="editMode"
        translate
        >UPDATE_COVER_IMAGE</label
      >

      <button
        *ngIf="isShowAllFile"
        type="button"
        class="btn btn-outline-secondary"
        (click)="showAllFiles()"
      >
        <span translate>SHOW_ALL_FILES</span>
      </button>

      <button
        *ngIf="isPortalManager"
        type="button"
        class="btn"
        (click)="toggleEditMode()"
        [ngClass]="editMode ? 'text-danger' : 'text-success'"
      >
        <span *ngIf="editMode" translate>GO_DISPLAY</span>
        <span *ngIf="!editMode" translate>GO_EDIT</span>
        <span
          class="icon ic-md"
          [ngClass]="editMode ? 'icon-view-content' : 'icon-edit-content'"
        ></span>
      </button>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div *ngIf="treeNode" class="col-sm-3">
          <app-ecpl-document-tree
            *ngIf="treeNode"
            treeId="adminManualsTree"
            [(ecplTreeData)]="treeNode"
            [(ecplActiveNode)]="treeActiveNode"
            [(ecplTreeCheckbox)]="editMode"
            (ecplNodeEvent)="catchEvent($event)"
            [isAdminManual]="upConfig.isAdminManuals"
            [(ecplApiResponse)]="apiResponse"
            [businessMode]="busMode"
            [adminUsers]="adminUsers"
          >
          </app-ecpl-document-tree>
        </div>
        <div class="col-sm-9">
          <div *ngIf="isLar == false">
            <div *ngIf="coverImg == false">
              <ecpl-document-explorer
                [businessMode]="busMode"
                [uploaderConfig]="upConfig"
                [uploadedFiles]="uploadedFiles"
                (saveChanges)="saveUploaded($event)"
                (activeFolder)="setActiveNodeIntree($event)"
                [(triggerUploader)]="triggerUploader"
                [(ecplApiResponse)]="apiResponse"
                [adminUsers]="adminUsers"
              >
              </ecpl-document-explorer>
            </div>
            <div *ngIf="coverImghide == false">
              <div *ngIf="treeActiveNode == null">
                <img
                  [src]="coverImage"
                  *ngIf="coverImage != ''"
                  alt=""
                  width="100%"
                />
                <img
                  [src]="defaultManualImg"
                  *ngIf="coverImage == ''"
                  alt=""
                  width="100%"
                />
              </div>
            </div>
          </div>
          <div *ngIf="isLar == true">
            <ecpl-lar-viewer
              (saveChanges)="saveUploaded($event)"
              [larSrc]="uploadedFiles"
              [mode]="editMode"
              [name]="filename"
              [formTitle]="upConfig.title"
            ></ecpl-lar-viewer>
          </div>
        </div>
      </div>
      <div class="saving-loader overlay" *ngIf="showSavingLoader">
        <div class="saving-loader-message text-center">
          <img src="/assets/icons/blocks.gif" alt="" />
          <p translate>SAVING</p>
        </div>
      </div>
    </div>
  </div>
</div>
<toaster-component></toaster-component>
<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>
