<div class="tab-content">
  <div class="page-title">
    <span translate>ADD_EQUIPMENTS</span>
  </div>
  <form [formGroup]="equipmentForm">
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="TypeEquip">
            <span translate>TYPE_OF_EQUIPMENT</span>
            <span class="required">*</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [filter]="true"
              (onChange)="onEquipmentChanged($event, ed)"
              #ed
              formControlName="EquipmentParent"
              id="Type"
              [options]="types"
              [style]="{ width: '100%' }"
            >
            </p-dropdown>
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="addType = !addType"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages
            [control]="equipmentForm.controls['EquipmentParent']"
          ></control-messages>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="Name">
            <span translate>EQUIPMENT</span>
            <span class="required">*</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [filter]="true"
              (onChange)="onTypeChanged($event)"
              formControlName="EquipmentType"
              id="Name"
              [options]="equipments"
              [style]="{ width: '100%' }"
            ></p-dropdown>
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="addEquip = !addEquip"
              [disabled]="isDisable"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages
            [control]="equipmentForm.controls['EquipmentType']"
          ></control-messages>
        </div>
      </div>
      <div class="col-md-12">
        <button
          class="btn btn-outline-primary"
          type="button"
          [disabled]="!equipmentForm.valid"
          (click)="addEquipment()"
        >
          <span translate>ADD</span>
        </button>
      </div>
    </div>
  </form>
  <div class="table-view">
    <!-- Table starts -->
    <p-table
      #equipmentTable
      [value]="bequipobj"
      [rows]="5"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      exportFilename="Equipments"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
    >
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th>{{ "TYPE_OF_EQUIPMENT" | translate }}</th>
          <th>{{ "EQUIPMENT" | translate }}</th>
          <th></th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-equipmentType>
        <tr>
          <td>
            <span>{{ equipmentType.EquipmentParent }}</span>
          </td>
          <td>
            <span>{{ equipmentType.EquipmentType }}</span>
          </td>
          <td>
            <a
              (click)="deleteEquipment(equipmentType.Id)"
              class="btn btn-icon"
              title="{{ 'DELETE' | translate }}"
              routerLinkActive="active"
            >
              <i class="icon ic-sm icon-trash"></i>
            </a>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="3">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
  <div class="action-btns-wrapper text-right">
    <a
      class="btn btn-primary"
      title="{{ 'DELETE' | translate }}"
      routerLinkActive="active"
    >
      <span translate>NEXT</span>
      <span class="icon ic-xs icon-forward"></span>
    </a>
  </div>
  <p-confirmDialog
    header="{{ 'CONFIRMATION' | translate }}"
    icon="pi pi-question-circle"
    width="425"
    acceptLabel="{{ 'SWITCH_YES' | translate }}"
    rejectLabel="{{ 'SWITCH_NO' | translate }}"
  ></p-confirmDialog>
  <p-dialog
    header="{{ 'ADD_EPUIPMENT_TYPE' | translate }}"
    [(visible)]="addType"
    [style]="{ width: '500px', height: '500px' }"
    [resizable]="false"
    [draggable]="false"
    (onHide)="onEquipmentTypeClose()"
  >
    <form [formGroup]="equipmentTypeForm">
      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>TYPE_OF_EQUIPMENT</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="text"
            #title
            class="label-input form-control"
            formControlName="Name"
            maxlength="100"
          />
          <span class="text-danger" *ngIf="isNameExists">{{
            existsAlerttext
          }}</span>
        </div>
      </div>
    </form>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="viewType = !viewType"
        >
          <span translate>VIEW</span>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!equipmentTypeForm.valid"
          (click)="saveEquipType()"
        >
          <span translate>SAVE</span>
        </button>
      </div>
      <div *ngIf="viewType">
        <div class="table-view">
          <!-- Table starts -->
          <p-table
            #typeofEquipType
            [value]="editTypes"
            [rows]="5"
            [paginator]="true"
            [pageLinks]="3"
            [rowsPerPageOptions]="[5, 10, 20]"
            [responsive]="true"
          >
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "EQUIPMENT_TYPE" | translate }}</th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <input
                    pInputText
                    type="text"
                    (input)="
                      typeofEquipType.filter(
                        $any($event.target)?.value,
                        'Name',
                        'contains'
                      )
                    "
                    placeholder=""
                    class="p-column-filter"
                  />
                </th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-business>
              <tr>
                <td>{{ business.Name }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'EDIT' | translate }}"
                    (click)="editType(business)"
                  >
                    <i
                      class="icon ic-sm icon-pencil-tip"
                      aria-hidden="true"
                    ></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon"
                    (click)="deleteType(business.Id)"
                    title="{{ 'DELETE' | translate }}"
                  >
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td colspan="2">
                  {{ "NO_RECORDS_FOUND" | translate }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table ends -->
        </div>
      </div>
    </p-footer>
  </p-dialog>
  <p-dialog
    header="{{ 'ADD_EQUIPMENT' | translate }}"
    [(visible)]="addEquip"
    (onHide)="onEquipmentClose()"
    [style]="{ width: '500px', height: '500px' }"
    [resizable]="false"
    [draggable]="false"
  >
    <form [formGroup]="addEquipmentForm">
      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>EQUIPMENT</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="text"
            #title
            class="label-input form-control"
            formControlName="Name"
            maxlength="100"
          />
          <span class="text-primary" *ngIf="isNameExists">{{
            existsAlerttext
          }}</span>
        </div>
      </div>
    </form>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="viewPosition = !viewPosition"
        >
          <span translate>VIEW</span>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!addEquipmentForm.valid"
          (click)="saveEquipment()"
        >
          <span translate>SAVE</span>
        </button>
      </div>
      <div *ngIf="viewPosition">
        <div class="table-view">
          <!-- Table starts -->
          <p-table
            #typeofEquip
            [value]="editEquipments"
            [rows]="5"
            [paginator]="true"
            [pageLinks]="3"
            [rowsPerPageOptions]="[5, 10, 20]"
            [responsive]="true"
          >
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "EQUIPMENT_NAME" | translate }}</th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <input
                    pInputText
                    type="text"
                    (input)="
                      typeofEquip.filter(
                        $any($event.target)?.value,
                        'Name',
                        'contains'
                      )
                    "
                    placeholder=""
                    class="p-column-filter"
                  />
                </th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-business>
              <tr>
                <td>{{ business.Name }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'EDIT' | translate }}"
                    (click)="editEquipment(business)"
                  >
                    <i
                      class="icon ic-sm icon-pencil-tip"
                      aria-hidden="true"
                    ></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon"
                    (click)="deleteEquipmentInModal(business.Id)"
                    title="{{ 'DELETE' | translate }}"
                  >
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td colspan="2">
                  {{ "NO_RECORDS_FOUND" | translate }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table ends -->
        </div>
      </div>
    </p-footer>
  </p-dialog>
  <router-outlet></router-outlet>
  <toaster-component></toaster-component>
</div>
