import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';

import { BaseServices } from './../../kuba.services';
import { ManualService } from '../services/manual.services';
import { KubaGeneralService } from './../../kuba-general.services';
import { ManualView } from '../models/index';
import { Rights } from './../../../_models/feature';
import { TranslateService } from '@ngx-translate/core';
import { ArticleApproveDetails } from 'src/app/shared/ecpl-article-viewer/interfaces';
import { HelperService } from 'src/app/_services/helper.service';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { Subscription } from 'rxjs';
@Component({
  selector: 'manual-list',
  templateUrl: 'manual-list.component.html',
  styleUrls: ['manual-list.component.css']
})
export class ManualListComponent implements OnInit, OnChanges, OnDestroy {
  manualList: ManualView[];
  approvedList: any[];
  loading = false;
  levelKeyName: string;
  isAdminMode = false;
  isRightsEnabled = false;
  Portal = null;
  safetyManual = 'SAFETY_MANUAL_NAME';
  status = 'STATUS';
  CreatedDate = 'ASSIGNED_DATE';
  LastEditedDate = 'LAST_EDITED';
  EditedBy = 'EDITED_BY';
  CreatedBy = 'MANUAL_ASSIGNED_BY';
  Actions = 'ACTIONS';
  EditManual = 'EDIT_MANUAL';
  DeleteManual = 'DELETE_MANUAL';
  isViewManual = true;
  statusList: SelectItem[];
  portalList: SelectItem[];
  businessList: SelectItem[];
  list: SelectItem[];
  currentUserRole: string;
  roleId: number;
  ddlManual: SelectItem[];
  // collapse button
  rowID: number = null!;
  isCollapsed = false;
  hideApproved = false;
  adminUsers: any = [];
  busMode: string;
  displayDialogForApproval = false;
  manualTitle: string;
  approvedDate: any;
  selectManual: ManualView;
  ddartile: any[];
  isGridView = true; // Default to grid view
  private subscriptions: Subscription[] = [];
  /**
   * Date picker configuration option
   */
  public defaultDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    inline: false,
    height: '32px',
    selectionTxtFontSize: '14px',
    disableSince: this.getCurrentDate(),
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT')
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER')
    }
  };

  /**
   * Date picker configuration option
   */
  public eventDateOptions: IMyDpOptions = this.defaultDateOptions;
  @Output() onManualEdit = new EventEmitter<ManualView>();
  @Output() onManualDelete = new EventEmitter<number>();
  @Input() manualType: string;
  @Input()
  set manuals(value: ManualView[]) {
    if (value) {
      this.manualList = value;
      this.manualList.forEach((x: any) => {
        if (x.ApprovedDate !== null) {
          x.ApprovedDate =
            x.ApprovedDate !== null
              ? HelperService.formatInputDate(x.ApprovedDate)
              : null;
        } else {
          x.ApprovedDate = null;
        }
      });
    }
  }

  /**
   * constructor
   * @param manualServices {ManualService}
   * @param route {ActivatedRoute}
   * @param confirmationService {ConfirmationService}
   * @param router {Router}
   * @param kubaGeneralService {KubaGeneralService}
   */
  constructor(
    private manualServices: ManualService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    public router: Router,
    private kubaGeneralService: KubaGeneralService,
    private translate: TranslateService,
    private manualService: ManualService,
    private businessService: BusinessServices
  ) {}

  /**
   * Initialization
   */
  ngOnInit(): void {
    document.querySelector('body').classList.remove('opened');
    this.busMode = this.route.snapshot.data['businessMode'];
    this.roleId = +BaseServices.roleId;
    if (BaseServices.UserRole === 'Admin') {
      this.isAdminMode = true;
      this.isGridView = false;
    }
    this.currentUserRole = BaseServices.UserRole;
    // To hide side menu bar
    this.subscriptions.push(
      this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
        this.statusList = [];
        this.statusList.push(
          { label: val.SELECT, value: null },
          { label: val.INACTIVE, value: 2 },
          { label: val.ACTIVE, value: 1 }
        );
      })
    );

    if (+BaseServices.roleId > 2) {
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
          this.ddlManual = [];
          this.ddlManual.push({ label: val.SELECT, value: null });
          this.manualList.forEach((element: any) => {
            this.ddlManual.push({
              label: element.Title,
              value: element.AppManualId
            });
          });
        })
      );
    }

    if (BaseServices.roleId == '1' || BaseServices.roleId == '2') {
      this.subscriptions.push(
        this.kubaGeneralService.getPortalList().subscribe((result: any) => {
          this.portalList = [];
          this.subscriptions.push(
            this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
              this.portalList = [];
              this.portalList.push({ label: val.SELECT, value: null });
            })
          );
          result.forEach(element => {
            this.portalList.push({ label: element.Name, value: element.Id });
          });
        })
      );

      let aUsers = this.route.snapshot.data['adminUsers'];
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
          this.adminUsers = [];
          if (+BaseServices.roleId === 1) {
            this.adminUsers.push({ Name: val.SELECT, Id: null });
          }
          if (aUsers != null && aUsers.length > 0) {
            aUsers.forEach((x: any) => {
              this.adminUsers.push({ Name: x.Name, Id: x.Id });
            });
          }
        })
      );

      this.subscriptions.push(
        this.businessService
          .getAllAdminBusinesses()
          .subscribe((result: any) => {
            this.businessList = [];
            this.subscriptions.push(
              this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.businessList = [];
                this.businessList.push({ label: val.SELECT, value: null });
              })
            );
            result.forEach(element => {
              this.businessList.push({
                label: element.CompanyName,
                value: element.Id
              });
            });
          })
      );
    }
    this.businessList = [];
    if (BaseServices.UserRole === 'Guest') {
      this.onViewManual();
    }
  }

  /**
   * on destory component
   */
  ngOnDestroy() {
    document.querySelector('body').classList.add('opened');
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }

  ngOnChanges(changes: any) {
    switch (this.manualType) {
      case 'common':
        this.levelKeyName = 'Application';
        break;
      case 'portal':
        this.levelKeyName = this.translate.instant('PORTAL_TITLE');
        break;
      case 'business':
        this.levelKeyName = this.translate.instant('BUSINESS_NAME');
        break;
      default:
        this.levelKeyName = 'Application';
        break;
    }
  }
  /**
   * edit manuals
   * @param manual
   */
  editManual(manual: ManualView) {
    this.onManualEdit.emit(manual);
  }

  /**
   * get current date
   */
  getCurrentDate() {
    let dateFormat = new Date();
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate() + 1
    };
  }

  /**
   *
   * @param manual
   */
  approvedManual(manual: any) {
    if (+BaseServices.roleId > 3) {
      if (+BaseServices.roleId === 5) {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.EDIT_MANUALS;
        this.isViewManual = BaseServices.checkUserRights(
          userRightsId,
          currentUserRole
        );
        if (!this.isViewManual) {
          let result: any = true;
          return result;
        }
      } else {
        return true;
      }
    }
    this.selectManual = manual;
    this.manualTitle = manual.Title;
    this.approvedDate = manual.ApprovedDate;
    this.displayDialogForApproval = true;
  }

  getBusinessManualList() {}

  onDateChanged(event: IMyDateModel) {
    this.manualList.forEach((x: any) => {
      if (x.Id === this.selectManual.Id) {
        if (event.epoc === 0) {
          x.ApprovedDate = null;
        } else {
          x.ApprovedDate = event;
        }
      }
    });
  }

  checkManualRights(): boolean {
    if (+BaseServices.roleId === 3) {
      return false;
    } else if (+BaseServices.roleId === 5) {
      let currentUserRole = BaseServices.UserRole;
      let userRightsId = Rights.EDIT_MANUALS;
      this.isViewManual = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
      if (this.isViewManual === true) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  printStatementRights(manual: any) {
    if (+BaseServices.roleId === 3) {
      if (
        typeof manual.ApprovedStatus !== 'undefined' &&
        manual.ApprovedStatus !== null &&
        manual.ApprovedStatus !== '' &&
        manual.ApprovedStatus.split('-')[1] === '1'
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  /**
   * delete manuls
   */

  deleteProject(appManualLevelId: number, manualTitle: string) {
    this.confirmationService.confirm({
      message:
        'Are you sure you want to delete this ' + manualTitle + ' manual?',
      accept: () => {
        this.onManualDelete.emit(appManualLevelId);
      }
    });
  }
  /**
   * view manuals based on guest rights
   */
  onViewManual() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.VIEW_MANUALS;
    this.isViewManual = BaseServices.checkUserRights(
      userRightsId,
      currentUserRole
    );
    if (this.isViewManual === false) {
      this.isRightsEnabled = true;
    }
  }

  // This will emit all file save/update action
  saveEmitter(eventData: any) {
    switch (eventData.eventName) {
      case 'SaveArticle':
        this.catchEvent(eventData);
        break;
      default:
        break;
    }
  }

  // Events form Tree
  catchEvent(event: any): void {
    switch (event.eventName) {
      case 'SaveArticle':
        let version = event.item.articleContent.article.version;
        this.subscriptions.push(
          this.manualService
            .saveArticle(
              0, // this.manualId
              event,
              +0, // this.mPortalId
              0
            )
            .subscribe(
              (response: any) => {
                if (response) {
                  BaseServices.setArticleId(response.Id);
                  this.subscriptions.push(
                    this.manualService
                      .newArticleText(
                        0,
                        response.Id,
                        event,
                        false,
                        0,
                        0,
                        +version
                      )
                      .subscribe((result: any) => {
                        if (result) {
                        }
                      })
                  );
                }
              },
              (error: any) => {
                console.log('Save article error : ', error);
              },
              () => console.log('save article Complete')
            )
        );
        break;
      default:
        break;
    }
  }

  onClickYes() {
    this.displayDialogForApproval = false;
    this.approvedList = [];
    this.hideApproved = false;
    this.loading = true;
    let aData: ArticleApproveDetails = {
      ApplicationId: +BaseServices.ApplicationId,
      BusinessId: +this.selectManual.BusinessId,
      ManualId: +this.selectManual.AppManualId,
      ApprovedDate: this.approvedDate.jsdate,
      UserId: +BaseServices.UserId
    };
    this.subscriptions.push(
      this.manualServices.approveBusinessArticle(aData).subscribe(result => {
        if (result) {
          this.subscriptions.push(
            this.manualServices
              .getCommonManuallistByBusinessLogin(+this.selectManual.BusinessId)
              .subscribe(result => {
                if (result) {
                  this.manualList = result;
                  this.manualList.forEach((x: any) => {
                    if (x.ApprovedDate !== null) {
                      x.ApprovedDate =
                        x.ApprovedDate !== null
                          ? HelperService.formatInputDate(x.ApprovedDate)
                          : null;
                    } else {
                      x.ApprovedDate = null;
                    }
                  });
                  this.loading = false;
                }
              })
          );
        } else {
          this.loading = false;
        }
      })
    );
  }

  onClickCancel() {
    this.displayDialogForApproval = false;
  }

  /**
   * set collapse value
   * @param i {number}
   */
  public setCollapse(i: number): boolean {
    let isCollapsed = i === this.rowID;
    return !isCollapsed;
  }

  /**
   *  toggle expand icon click
   * @param i {number}
   */
  toggleClick(i: number): void {
    this.rowID = i;
    this.isCollapsed = !this.isCollapsed;
  }

  /**
   *  toggle collapse icon click
   * @param i {number}
   */
  toggleClickHide(i: number): void {
    this.rowID = null!;
  }
  /**
   * check all checkbox
   */
  toggleSelect = function (event: any) {};

  toggleView() {
    this.isGridView = !this.isGridView;
  }
}
